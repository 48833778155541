import { act } from "react";

export const SignInReducer = (
  state = {
    LiveOrderData: [],
    isDataLoaded: false,
    editRetailerSuccess: false,
    editStoreSuccess: false,
    editRetailerFailed: false,
    editStoreFailed: false,
    searchSuccess: false,
    searchFailed: false,
    retailerSearch: false,
    searchingstoredetails: false,
    searchDetailsSuccess: false,
    searchDetailsFailed: false,
    searchingorder: false,
    searchingactiveorder: false,
    searchActiveOrderSuccess: false,
    userslistloading: false,
    userslistsuccess: false,
    userslistfailed: false,
    userloading: false,
    usersuccess: false,
    userfailed: false,
    complaintdetailsloading: false,
    complaintdetailssuccess: false,
    complaintdetailsfailed: false,
    blockeduser: false,
    blockinguser: false,
    blockuserfailed: false,
    searchData: [],
    Users: [],
    searchOrderData: { items: [], hasNext: false },
    searchActiveOrderData: { items: [], hasNext: false },
    solvingcomplaint: false,
    solvedcomplaint: false,
    solvingcomplaintfailed: false,
    deleteingnewproduct: false,
    deletenewproductsuccess: false,
    deletenewproductfailed: false,
    isDepartmentLoaded: false,
    updateDep: false,
    searchDetails: [],
  },
  action
) => {
  switch (action.type) {
    case "CLOSE_SNACKBARS":
      return {
        ...state,
        editRetailerSuccess: false,
        editRetailerFailed: false,
        passwordResetSuccess: false,
        passwordResetFailed: false,
        editStoreSuccess: false,
        editStoreFailed: false,
        addnewproductsuccess: false,
        addnewproductfailed: false,
        deletenewproductsuccess: false,
        deletenewproductfailed: false,
        percentagecouponsuccess: false,
        percentagecouponFailed: false,
        Flatcouponsuccess: false,
        FlatcouponFailed: false,
        Deliverycouponsuccess: false,
        DeliverycouponFailed: false,
        AssigningCouponssuccess: false,
        AssigningCouponsFailed: false,
        EditingCouponssuccess: false,
        EditingCouponsFailed: false,
        productAdded: false,
        productEdited: false,
        productEditFailed: false,
        productAddedFailed: false,
        edittedStoreLocation: null,
      };
    case "TEXT_EDIT_CHANGE":
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    case "EDIT_LOCATION":
      return {
        ...state,
        searchDetails: {
          store: {
            ...state.searchDetails.store,
            storeLocation: action.payload.location,
            storeCoordinates: {
              coordinates: [action.payload.longitude, action.payload.latitude],
            },
          },
        },
      };
    case "SAVE_DETAILS":
      return {
        ...state,
        searchDetails: {
          store: action.payload,
        },
      };
    case "TEXT_STORE_CHANGE":
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    case "NEW_PREPERATIONTIME":
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    case "NEW_PACKINGCHARGE":
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    case "NEW_COORDINATES": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "NEW_MAX_DISTANCE": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "NEW_EDIT_CHARGES": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "SCHEDULE_EDIT": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "CHECKBOX_EDIT": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "SHOPASSISTANT_FLOW_UPDATE": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "SUBSCRIPTION_EDIT": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "Store_Search":
      return {
        ...state,
        clearedsearchresults: false,
        Stores: {
          newStores: {
            params:
              action.payload.data && action.payload.data.searchStoreName
                ? action.payload.data.searchStoreName
                : action.payload,
          },
        },
      };
    case "ActiveOrder_Search":
      return {
        ...state,
        ActiveOrders: action.payload,
      };
    case "User_Search":
      return {
        ...state,
        Users: action.payload,
      };
    // case "Order_Search":
    // 	return{
    // 	   ...state,
    // 	   Orders:action.payload
    //    }
    case "SUBSCRIPTION_DATE": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "SUBSCRIPTION_AMOUNT": {
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    }
    case "EDIT_STORE_SUCCESS": {
      return {
        ...state,
        storeDetails: {
          data: { storeDetails: action.payload.store },
        },
        editStoreSuccess: true,
        editStoreFailed: false,
      };
    }
    case "EDIT_STORE_FAILED": {
      return {
        ...state,
        editStoreSuccess: false,
        editStoreFailed: true,
      };
    }
    case "EDIT_RETAILER_SUCCESS": {
      return {
        ...state,
        searchDetails: {
          store: {
            ...action.payload.details,
            forAdmin: action.payload.response.updateretailer,
          },
        },
        editRetailerSuccess: true,
        editRetailerFailed: false,
      };
    }
    case "EDIT_RETAILER_FAILED": {
      return {
        ...state,
        editRetailerSuccess: false,
        editRetailerFailed: true,
      };
    }
    case "CLEAR_STORE_SEARCH_RESULTS": {
      return {
        ...state,
        clearedsearchresults: true,
        searchData: { items: [], hasNext: false },
      };
    }
    case "SEARCHING": {
      return {
        ...state,
        searching: true,
        clearedsearchresults: false,
        searchSuccess: false,
      };
    }
    case "SEARCH_SUCCESS": {
      return {
        ...state,
        searching: false,
        clearedsearchresults: false,
        searchSuccess: true,
        searchData: action.payload.data,
      };
    }
    case "SEARCH_FAILED": {
      return {
        ...state,
        searching: false,
        clearedsearchresults: false,
        searchFailed: true,
      };
    }
    case "SEARCH_ERROR": {
      return {
        ...state,
        searching: false,
        clearedsearchresults: false,
        searchFailed: true,
      };
    }
    case "CLEAR_SEARCHORDER_RESULT": {
      return {
        ...state,
        searchingorder: false,
        searchOrderSuccess: false,
        searchOrderData: { items: [], hasNext: false },
      };
    }
    case "SEARCHING_ORDER": {
      return {
        ...state,
        searchingorder: true,
        searchOrderSuccess: false,
      };
    }
    case "SEARCH_ORDER_SUCCESS": {
      return {
        ...state,
        searchingorder: false,
        searchOrderSuccess: true,
        searchOrderData: action.payload.data.searchorder,
      };
    }
    case "SEARCH_ORDER_FAILED": {
      return {
        ...state,
        searchingorder: false,
        searchOrderFailed: true,
      };
    }
    case "SEARCH_ORDERERROR": {
      return {
        ...state,
        searchingorder: false,
        searchOrderFailed: true,
      };
    }
    case "CLEAR_SEARCH_ORDER": {
      return {
        ...state,
        searchingorder: false,
        searchOrderSuccess: false,
        searchOrderData: { items: [], hasNext: false },
      };
    }
    case "SEARCHING_ACTIVEORDER": {
      return {
        ...state,
        searchingactiveorder: true,
        searchActiveOrderSuccess: false,
      };
    }
    case "SEARCH_ACTIVEORDER_SUCCESS": {
      return {
        ...state,
        searchingactiveorder: false,
        searchActiveOrderSuccess: true,
        searchActiveOrderData: {
          items: action.payload.data.activeOrders.items,
          hasNext: action.payload.data.activeOrders.hasNext,
        },
      };
    }
    case "SEARCH_RETAILER_SUCCESS": {
      return {
        ...state,
        retailerSearch: true,
        Retailer: action.payload,
      };
    }
    case "SEARCH_RETAILER_FAILED": {
      return {
        ...state,
        retailerSearch: false,
      };
    }
    case "SEARCH_ACTIVEORDER_FAILED": {
      return {
        ...state,
        searchingactiveorder: false,
        searchActiveOrderFailed: true,
      };
    }
    case "SEARCH_ACTIVEORDERERROR": {
      return {
        ...state,
        searchingactiveorder: false,
        searchActiveOrderFailed: true,
      };
    }
    case "CLEAR_SEARCH_ACTIVE_ORDER": {
      return {
        ...state,
        searchActiveOrderData: { items: [], hasNext: false },
      };
    }
    case "LOADING_ORDER_DETAILS": {
      return {
        ...state,
        loadingorder: true,
        OrderDetailsSuccess: false,
      };
    }
    case "MULTIPLE_UPLOAD_SUCCESS":
      return {
        ...state,
        multipleImageLoading: false,
        multipleImageUploaded: true,
        multipleSignedUrl: action.payload,
        multiFile: false,
        multiImage: action.payload,
      };
    case "MULTIPLE_UPLOAD_FAILURE":
      return {
        ...state,
        multipleImageLoading: false,
        multipleImageUploaded: false,
        multiplefailed: true,
        multipleError: action.payload,
      };

    case "ORDER_DETAILS_SUCCESS": {
      return {
        ...state,
        loadingorder: false,
        OrderDetailsSuccess: true,
        OrderDetails: action.payload.data,
      };
    }
    case "ORDER_DETAILS_FAILED": {
      return {
        ...state,
        loadingorder: false,
        OrderDetailsFailed: true,
      };
    }
    case "ORDER_DETAILS_ERROR": {
      return {
        ...state,
        loadingorder: false,
        OrderDetailsFailed: true,
      };
    }
    case "SEARCHING_STORE_DETAILS": {
      return {
        ...state,
        searchingstoredetails: true,
      };
    }
    case "SEARCH_DETAILS_SUCCESS": {
      return {
        ...state,
        searchingstoredetails: false,
        searchDetailsSuccess: true,
        searchDetails: action.payload.data,
      };
    }
    case "SEARCH_DETAILS_FAILED": {
      return {
        ...state,
        searchingstoredetails: false,
        searchDetailsFailed: true,
        searchDetailsSuccess: false,
      };
    }
    case "SEARCH_DETAILS_ERROR": {
      return {
        ...state,
        searchingstoredetails: false,
        searchDetailsFailed: true,
        searchDetailsSuccess: false,
      };
    }
    case "STATUS_STORE_CHANGE":
      return {
        ...state,
        searchDetails: { store: action.payload },
      };
    case "LOADING_USERSLIST": {
      return {
        ...state,
        userslistloading: true,
        userslistsuccess: false,
      };
    }
    case "USERSLIST_SUCCESS": {
      return {
        ...state,
        userslistloading: false,
        userslistsuccess: true,
        UsersList: action.payload.data,
      };
    }
    case "USERSLIST_FAILED": {
      return {
        ...state,
        userslistloading: false,
        userslistsuccess: false,
        userslistfailed: true,
      };
    }
    case "LOADING_USER": {
      return {
        ...state,
        userloading: true,
        usersuccess: false,
      };
    }
    case "USER_SUCCESS": {
      return {
        ...state,
        userloading: false,
        usersuccess: true,
        User: action.payload.data,
      };
    }
    case "USER_FAILED": {
      return {
        ...state,
        userloading: false,
        usersuccess: false,
        userfailed: true,
      };
    }
    case "LOADING_COMPLAINTS": {
      return {
        ...state,
        complaintsloading: true,
        complaintssuccess: false,
      };
    }
    case "COMPLAINTS_SUCCESS": {
      return {
        ...state,
        complaintsloading: false,
        complaintssuccess: true,
        Complaints: action.payload.data,
      };
    }
    case "COMPLAINTS_FAILED": {
      return {
        ...state,
        complaintsloading: false,
        complaintssuccess: false,
        complaintsfailed: true,
      };
    }
    case "LOADING_COMPLAINT": {
      return {
        ...state,
        complaintdetailsloading: true,
        complaintdetailssuccess: false,
      };
    }
    case "COMPLAINT_SUCCESS": {
      return {
        ...state,
        complaintdetailsloading: false,
        complaintdetailssuccess: true,
        Complaint: action.payload.data.complaint,
      };
    }
    case "COMPLAINT_FAILED": {
      return {
        ...state,
        complaintdetailsloading: false,
        complaintdetailssuccess: false,
        complaintdetailsfailed: true,
      };
    }
    case "LOADING_ORDER_COMPLAINT": {
      return {
        ...state,
        ordercomplaintloading: true,
        ordercomplaintsuccess: false,
      };
    }
    case "ORDER_COMPLAINT_SUCCESS": {
      return {
        ...state,
        ordercomplaintloading: false,
        ordercomplaintsuccess: true,
        OrderComplaint: action.payload.data.orderComplaints,
      };
    }
    case "ORDER_COMPLAINT_FAILED": {
      return {
        ...state,
        ordercomplaintloading: false,
        ordercomplaintsuccess: false,
        ordercomplaintfailed: true,
      };
    }
    case "ORDER_COMPLAINT_ERROR": {
      return {
        ...state,
        ordercomplaintloading: false,
        ordercomplaintsuccess: false,
        ordercomplaintfailed: true,
      };
    }
    case "BLOCKING_USER": {
      return {
        ...state,
        blockinguser: true,
        blockeduser: false,
        unblockeduser: false,
        unblockinguser: false,
      };
    }
    case "BLOCK_USER_SUCCESS": {
      return {
        ...state,
        blockinguser: false,
        blockeduser: true,
        blockUserData: action.payload,
        unblockeduser: false,
        unblockinguser: false,
      };
    }
    case "BLOCK_USER_FAILED": {
      return {
        ...state,
        blockinguser: false,
        blockeduser: false,
        blockuserfailed: true,
        unblockeduser: false,
        unblockinguser: false,
      };
    }
    case "UNBLOCKING_USER": {
      return {
        ...state,
        unblockinguser: true,
        unblockeduser: false,
        blockeduser: false,
        blockinguser: false,
      };
    }
    case "UNBLOCK_USER_SUCCESS": {
      return {
        ...state,
        unblockinguser: false,
        unblockeduser: true,
        unblockUserData: action.payload,
        blockeduser: false,
        blockinguser: false,
      };
    }
    case "UNBLOCK_USER_FAILED": {
      return {
        ...state,
        unblockinguser: false,
        unblockeduser: false,
        unblockuserfailed: true,
        blockeduser: false,
        blockinguser: false,
      };
    }
    case "SENDING_ADMIN_ACTION": {
      return {
        ...state,
        sendingadminaction: true,
        sendadminaction: false,
      };
    }
    case "SEND_ACTION_SUCCESS": {
      return {
        ...state,
        sendingadminaction: false,
        sendadminaction: true,
        Adminaction: action.payload,
      };
    }
    case "SEND_ACTION_FAILED": {
      return {
        ...state,
        sendingadminaction: false,
        sendadminaction: false,
        sendadminactionfailed: true,
      };
    }
    case "CLOSING_COMPLAINT": {
      return {
        ...state,
        closingcomplaint: true,
        complaintclosed: false,
      };
    }
    case "CLOSE_COMPLAINT_SUCCESS": {
      return {
        ...state,
        closingcomplaint: false,
        complaintclosed: true,
        ComplaintClosedData: action.payload,
      };
    }
    case "CLOSE_COMPLAINT_FAILED": {
      return {
        ...state,
        closingcomplaint: false,
        complaintclosed: false,
        closingcomplaintfailed: true,
      };
    }
    case "SOLVING_COMPLAINT": {
      return {
        ...state,
        solvingcomplaint: true,
        solvedcomplaint: false,
      };
    }
    case "SOLVE_COMPLAINT_SUCCESS": {
      return {
        ...state,
        solvingcomplaint: false,
        solvedcomplaint: true,
        SolvedComplaintData: action.payload,
      };
    }
    case "SOLVE_COMPLAINT_FAILED": {
      return {
        ...state,
        solvingcomplaint: false,
        solvedcomplaint: false,
        solvingcomplaintfailed: true,
      };
    }
    case "CLEAR_CLOSED_COMPLAINT_PROPS": {
      return {
        ...state,
        closingcomplaint: false,
        complaintclosed: false,
        closingcomplaintfailed: false,
        solvingcomplaint: false,
        solvedcomplaint: false,
        solvingcomplaintfailed: false,
      };
    }
    case "DASHBOARD_LOADING": {
      return {
        ...state,
        loadingdashboard: true,
        dashboardsuccess: false,
        dashboardfailed: false,
      };
    }
    case "DASHBOARD_SUCCESS": {
      return {
        ...state,
        loadingdashboard: false,
        dashboardsuccess: true,
        dashboardData: action.payload,
        dashboardfailed: false,
      };
    }
    case "DASHBOARD_FAILED": {
      return {
        ...state,
        loadingdashboard: false,
        dashboardsuccess: false,
        dashboardfailed: true,
      };
    }
    case "NEWPRODUCTS_LOADING": {
      return {
        ...state,
        loadingnewproducts: true,
        newproductssuccess: false,
        newproductsfailed: false,
      };
    }
    case "NEWPRODUCTS_SUCCESS": {
      return {
        ...state,
        loadingnewproducts: false,
        newproductssuccess: true,
        newproductsfailed: false,
        NewProducts: action.payload.data.masterProductsApprovalList,
      };
    }
    case "NEWPRODUCTS_FAILED": {
      return {
        ...state,
        loadingnewproducts: false,
        newproductssuccess: false,
        newproductsfailed: true,
      };
    }
    case "NEWPRODUCTDETAILS_LOADING": {
      return {
        ...state,
        loadingnewproductdetails: true,
        newproductdetailssuccess: false,
        newproductdetailsfailed: false,
      };
    }
    case "NEWPRODUCTDETAILS_SUCCESS": {
      return {
        ...state,
        loadingnewproductdetails: false,
        newproductdetailssuccess: true,
        newproductdetailsfailed: false,
        NewProductDetails: action.payload.data.viewParticularMasterProduct,
      };
    }
    case "NEWPRODUCTDETAILS_FAILED": {
      return {
        ...state,
        loadingnewproductdetails: false,
        newproductdetailssuccess: false,
        newproductdetailsfailed: true,
      };
    }
    case "NEWPRODUCT_EDIT_CHANGE": {
      return {
        ...state,
        NewProductDetails: action.payload,
      };
    }
    case "DEPARTMENTS_LOADING": {
      return {
        ...state,
        loadingdepartments: true,
        departmentslistsuccess: false,
        departmentslistfailed: false,
      };
    }
    case "DEPARTMENTS_SUCCESS": {
      return {
        ...state,
        loadingdepartments: false,
        departmentslistsuccess: true,
        departmentslistfailed: false,
        DeparmentList: action.payload.data.masterdepartments,
      };
    }
    case "DEPARTMENTS_FAILED": {
      return {
        ...state,
        loadingdepartments: false,
        departmentslistsuccess: false,
        departmentslistfailed: true,
      };
    }
    case "CATEGORIES_LOADING": {
      return {
        ...state,
        loadingcategories: true,
        categorieslistsuccess: false,
        categorieslistfailed: false,
      };
    }
    case "CATEGORIES_SUCCESS": {
      return {
        ...state,
        loadingcategories: false,
        categorieslistsuccess: true,
        categorieslistfailed: false,
        CategoriesList: action.payload.data.mastercategory,
      };
    }
    case "CATEGORIES_FAILED": {
      return {
        ...state,
        loadingcategories: false,
        categorieslistsuccess: false,
        categorieslistfailed: true,
      };
    }
    case "CATEGORIES1_LOADING": {
      return {
        ...state,
        loadingcategories1: true,
        categories1listsuccess: false,
        categories1listfailed: false,
      };
    }
    case "CATEGORIES1_SUCCESS": {
      return {
        ...state,
        loadincategories1: false,
        categories1listsuccess: true,
        categories1listfailed: false,
        Categories1List: action.payload.data.mastercategory,
      };
    }
    case "CATEGORIES1_FAILED": {
      return {
        ...state,
        loadingcategories1: false,
        categories1listsuccess: false,
        categories1listfailed: true,
      };
    }
    case "CATEGORIES2_LOADING": {
      return {
        ...state,
        loadingcategories2: true,
        categories2listsuccess: false,
        categories2listfailed: false,
      };
    }
    case "CATEGORIES2_SUCCESS": {
      return {
        ...state,
        loadincategories2: false,
        categories2listsuccess: true,
        categories2listfailed: false,
        Categories2List: action.payload.data.mastercategory,
      };
    }
    case "CATEGORIES2_FAILED": {
      return {
        ...state,
        loadingcategories2: false,
        categories2listsuccess: false,
        categories2listfailed: true,
      };
    }
    case "ADDING_NEW_PRODUCT": {
      return {
        ...state,
        addingnewproduct: true,
        addnewproductsuccess: false,
        addnewproductfailed: false,
      };
    }
    case "ADD_NEW_PRODUCT_SUCCESS": {
      return {
        ...state,
        addingnewproduct: false,
        addnewproductsuccess: true,
        addnewproductfailed: false,
        AddNewProduct: action.payload.data,
      };
    }
    case "ADD_NEW_PRODUCT_FAILED": {
      return {
        ...state,
        addingnewproduct: false,
        addnewproductsuccess: false,
        addnewproductfailed: true,
      };
    }
    case "DELETING_NEW_PRODUCT": {
      return {
        ...state,
        deletingnewproduct: true,
        deletenewproductsuccess: false,
        deletenewproductfailed: false,
      };
    }
    case "DELETE_NEW_PRODUCT_SUCCESS": {
      return {
        ...state,
        deleteingnewproduct: false,
        deletenewproductsuccess: true,
        deletenewproductfailed: false,
        DeletedNewProduct: action.payload.data,
      };
    }
    case "DELETE_NEW_PRODUCT_FAILED": {
      return {
        ...state,
        deletingnewproduct: false,
        deletenewproductsuccess: false,
        deletenewproductfailed: true,
      };
    }
    case "DEPARTMENT_LIST_SUCCESS":
      return {
        ...state,
        isDepartmentLoaded: true,
        DepartmentList: action.payload,
      };
    case "DEPARTMENT_LIST_FAILED":
      return {
        ...state,
        isDepartmentLoaded: false,
        ErrorMessage: "Failed to Load Data",
      };
    case "CREATING_MASTER_PRODUCTS": {
      return {
        ...state,
        MasteraddingProduct: true,
      };
    }
    case "ADDING_PRODUCTS": {
      return {
        ...state,
        addingProduct: true,
        productAddedFailed: false,
      };
    }
    case "ADD_PRODUCT_SUCCESS": {
      return {
        ...state,
        productAdded: true,
        productAddedFailed: false,
        addingProduct: false,
        ProductData: action.payload,
        proImage: null,
        multiImage: null,
        fileListLogo: false,
        multiFile: false,
        MasterProductDetailsData: null,
        multipleSignedUrl: null,
        thumbnailUrl: null,
      };
    }
    case "ADD_PRODUCT_FAILED": {
      return {
        ...state,
        productAddedFailed: true,
        ProductError: action.payload,
        addingProduct: false,
        proImage: null,
        multiImage: null,
        fileListLogo: false,
        multiFile: false,
        MasterProductDetailsData: null,
        multipleSignedUrl: null,
        thumbnailUrl: null,
      };
    }
    case "UPDATING_PRODUCTS": {
      return {
        ...state,
        productEdited: false,
        proupdating: true,
        productEditFailed: false,
      };
    }
    case "UPDATE_PRODUCT_SUCCESS": {
      return {
        ...state,
        productEdited: true,
        proupdating: false,
        productEditFailed: false,
      };
    }
    case "UPDATE_PRODUCT_FAILED": {
      return {
        ...state,
        productEdited: false,
        proupdating: false,
        productEditFailed: true,
      };
    }
    case "UPDATE_PRODUCT_STATUS": {
      return {
        ...state,
        productEdited: true,
        proupdating: false,
        productEditFailed: false,
      };
    }
    case "UPDATE_STATUS_FAILED": {
      return {
        ...state,
        productEdited: false,
        proupdating: false,
        productEditFailed: true,
      };
    }
    case "SET_CRITERIA": {
      return {
        ...state,
        criteria: {
          from: 0,
          first: action.payload.from + 20,
          storeid: action.payload.storeid,
          department: action.payload.department,
          category: action.payload.category,
          category1: action.payload.category1,
          category2: action.payload.category2,
          status: action.payload.status,
          key: action.payload.key,
        },
      };
    }
    case "SEARCH_PRODUCT_LOADING": {
      return {
        ...state,
        searchLoading: true,
        searchMasterProductsuccess: false,
      };
    }
    case "CREATING_PERCENTAGE_COUPON": {
      return {
        ...state,
        percentagecouponLoading: true,
        percentagecouponsuccess: false,
        percentagecouponFailed: false,
      };
    }
    case "PERCENTAGECOUPON_CREATED": {
      return {
        ...state,
        percentagecouponLoading: false,
        percentagecouponsuccess: true,
        percentagecouponFailed: false,
      };
    }
    case "PERCENTAGECOUPON_CREATION_FAILED": {
      return {
        ...state,
        percentagecouponLoading: false,
        percentagecouponsuccess: false,
        percentagecouponFailed: true,
      };
    }
    case "CREATING_FLAT_COUPON": {
      return {
        ...state,
        FlatcouponLoading: true,
        Flatcouponsuccess: false,
        FlatcouponFailed: false,
      };
    }
    case "FLATCOUPON_CREATED": {
      return {
        ...state,
        FlatcouponLoading: false,
        Flatcouponsuccess: true,
        FlatcouponFailed: false,
      };
    }
    case "FLATCOUPON_CREATION_FAILED": {
      return {
        ...state,
        FlatcouponLoading: false,
        Flatcouponsuccess: false,
        FlatcouponFailed: true,
      };
    }
    case "CREATING_DELEIVERYFREE_COUPON": {
      return {
        ...state,
        DeliverycouponLoading: true,
        Deliverycouponsuccess: false,
        DeliverycouponFailed: false,
      };
    }
    case "DELIVERYFREECOUPON_CREATED": {
      return {
        ...state,
        DeliverycouponLoading: false,
        Deliverycouponsuccess: true,
        DeliverycouponFailed: false,
      };
    }
    case "DELEIVERYFREECOUPON_CREATION_FAILED": {
      return {
        ...state,
        DeliverycouponLoading: false,
        Deliverycouponsuccess: false,
        DeliverycouponFailed: true,
      };
    }
    case "SEARCH_PRODUCT_SUCCESS": {
      return {
        ...state,
        searchLoading: false,
        searchMasterProductsuccess: true,
        searchProductData: action.payload,
      };
    }
    case "SEARCH_PRODUCT_LOADING": {
      return {
        ...state,
        searchLoading: true,
        searchMasterProductsuccess: false,
      };
    }
    case "PRODUCT_SEARCH_LOADING": {
      return {
        ...state,
        searchLoading: true,
        searchMasterProductsuccess: false,
      };
    }
    case "PRODUCT_SEARCH_SUCCESS": {
      return {
        ...state,
        searchLoading: false,
        searchMasterProductsuccess: true,
        searchProductData: action.payload,
      };
    }
    case "PRODUCT_DETAILS_SUCCESS": {
      return {
        ...state,
        ProductDetailsSuccess: true,
        ProductDetailsData: action.payload,
      };
    }
    case "PRODUCT_DETAILS_FAILED": {
      return {
        ...state,
        ProductDetailsFailed: true,
      };
    }
    case "Product_Search":
      return {
        ...state,
        Products: action.payload,
      };
    case "LISTUSERCOUPONS_LOADING": {
      return {
        ...state,
        userCouponsLoading: true,
        userCouponssuccess: false,
        userCouponsFailed: false,
      };
    }
    case "LISTUSERCOUPONS_SUCCESS": {
      return {
        ...state,
        userCouponsLoading: false,
        userCouponssuccess: true,
        userCouponsFailed: false,
        userCoupons: action.payload,
      };
    }
    case "LISTUSERCOUPONS_FAILED": {
      return {
        ...state,
        userCouponsLoading: false,
        userCouponssuccess: false,
        userCouponsFailed: true,
      };
    }

    case "LISTALLCOUPONS_LOADING": {
      return {
        ...state,
        AllCouponsLoading: true,
        AllCouponssuccess: false,
        AllCouponsFailed: false,
      };
    }
    case "LISTALLCOUPONS_SUCCESS": {
      return {
        ...state,
        AllCouponsLoading: false,
        AllCouponssuccess: true,
        AllCouponsFailed: false,
        AllCoupons: action.payload,
      };
    }
    case "LISTALLCOUPONS_FAILED": {
      return {
        ...state,
        AllCouponsLoading: false,
        AllCouponssuccess: false,
        AllCouponsFailed: true,
      };
    }
    case "ASSIGNING_COUPON": {
      return {
        ...state,
        AssigningCouponsLoading: true,
        AssigningCouponssuccess: false,
        AssigningCouponsFailed: false,
      };
    }
    case "ASSIGNCOUPON_SUCCESS": {
      return {
        ...state,
        AssigningCouponsLoading: false,
        AssigningCouponssuccess: true,
        AssigningCouponsFailed: false,
      };
    }
    case "ASSIGNCOUPON_FAILED": {
      return {
        ...state,
        AssigningCouponsLoading: false,
        AssigningCouponssuccess: false,
        AssigningCouponsFailed: true,
        Assignerror: action.payload,
      };
    }

    case "EDITING_COUPON": {
      return {
        ...state,
        EditingCouponsLoading: true,
        EditingCouponssuccess: false,
        EditingCouponsFailed: false,
      };
    }
    case "EDIT_COUPON_SUCCESS": {
      return {
        ...state,
        EditingCouponsLoading: false,
        EditingCouponssuccess: true,
        EditingCouponsFailed: false,
      };
    }
    case "EDIT_COUPON_FAILED": {
      return {
        ...state,
        EditingCouponsLoading: false,
        EditingCouponssuccess: false,
        EditingCouponsFailed: true,
        Editingerror: action.payload,
      };
    }
    case "GET_BUSINESSTYPES_LOADING": {
      return {
        ...state,
        BusinessTypeLoading: true,
        BusinessTypesuccess: false,
        BusinessTypeFailed: false,
      };
    }
    case "GET_BUSINESSTYPES_SUCCESS": {
      return {
        ...state,
        BusinessTypeLoading: false,
        BusinessTypesuccess: true,
        BusinessTypeFailed: false,
        BusinessType: action.payload,
      };
    }
    case "GET_BUSINESSTYPES_FAILED": {
      return {
        ...state,
        BusinessTypeLoading: false,
        BusinessTypesuccess: false,
        BusinessTypeFailed: true,
      };
    }
    case "GETTING_LINK": {
      return {
        ...state,
        DynamicLinkLoading: true,
        DynamicLinksuccess: false,
        DynamicLinkFailed: false,
      };
    }
    case "GET_LINK_SUCCESS": {
      return {
        ...state,
        DynamicLinkLoading: false,
        DynamicLinksuccess: true,
        DynamicLink: action.payload.shareMyLink,
        DynamicLinkFailed: false,
      };
    }
    case "GET_LINK_FAILURE": {
      return {
        ...state,
        DynamicLinkLoading: false,
        DynamicLinksuccess: false,
        DynamicLinkFailed: true,
      };
    }
    case "SET_STORE_LOCATION": {
      return {
        ...state,
        edittedStoreLocation: action.payload,
      };
    }
    case "SET_ORDER_DETAILS": {
      return {
        ...state,
        searchDetailsSuccess: true,
        OrderDetails: {
          orderdetails: action.payload,
        },
        rejected: false,
      };
    }
    case "SET_LIVE_ORDER": {
      return {
        ...state,
        LiveOrderData: [...state.LiveOrderData, ...action.payload],
      };
    }
    case "DELETE_LIVE_ORDER": {
      return {
        ...state,
        LiveOrderData: [],
      };
    }
    case "SHOPASSISTANT_DETAILS_LOADING": {
      return {
        ...state,
        shopAssistantDetailsloading: true,
      };
    }
    case "SHOPASSISTSNT_DETAILS": {
      console.log("kikjoi", action.payload);
      return {
        ...state,
        OrderDetails: {
          orderdetails: {
            ...state.OrderDetails.orderdetails,
            shopAssistantId: action.payload,
          },
        },
      };
    }
    case "CHANGE_TO_ACTIVE": {
      return {
        ...state,
        ChangeToActiveLoading: true,
        ChangetoActiveStatusSuccess: false,
        ChangetoActiveStatusFailed: false,
      };
    }
    case "CHANGE_TO_ACTIVE_STATUS_SUCCESS": {
      return {
        ...state,
        ChangetoActiveStatusSuccess: true,
        ChangetoActiveStatusFailed: false,
        ChangeToActiveLoading: false,
        ChangetoActive: action.payload || [],
      };
    }
    case "CHANGE_TO_ACTIVE_STATUS_FAILED": {
      return {
        ...state,
        ChangetoActiveStatusSuccess: false,
        ChangetoActiveStatusFailed: true,
        ChangeToActiveLoading: false,
      };
    }
    case "CHANGE_TO_READY": {
      return {
        ...state,
        ChangeToReadyLoading: true,
        ChangetoReadyStatusSuccess: false,
        ChangetoReadyStatusFailed: false,
      };
    }
    case "CHANGE_ORDER_STATUS_TO_READY_SUCCESS": {
      return {
        ...state,
        ChangetoReadyStatusSuccess: true,
        ChangetoReadyStatusFailed: false,
        ChangeToReadyLoading: false,
        ChangetoReady: action.payload || [],
      };
    }
    case "CHANGE_ORDER_STATUS_TO_READY_FAILED": {
      return {
        ...state,
        ChangetoReadyStatusSuccess: false,
        ChangeToReadyLoading: false,
        ChangetoReadyStatusFailed: true,
      };
    }
    case "ORDERIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        orderImageLoading: false,
        orderImageUploaded: true,
        proImage: action.payload,
        newSignedUrl: action.payload,
        fileListLogo: false,
        orderImageFailed: false,
        masterUrl: null,
      };

    case "ORDERIMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        orderImageLoading: false,
        orderImageUploaded: false,
        orderImageFailed: true,
        orderUrlError: action.payload,
      };
    case "CHANGED_TO_REJECTED":
      return {
        ...state,
        rejectedDetails: action.payload,
      };
    case "CHANGE_TO_DELIVERY": {
      return {
        ...state,
        ChangeToDeliveryLoading: true,
        ChangetoDeliveryStatusSuccess: false,
        ChangetoDeliveryStatusFailed: false,
      };
    }
    case "CHANGE_ORDER_STATUS_TO_DELIVERY_SUCCESS": {
      return {
        ...state,
        ChangeToDeliveryLoading: false,
        ChangetoDeliveryStatusSuccess: true,
        ChangetoDeliveryStatusFailed: false,
        ChangetoDelivery: action.payload || [],
      };
    }
    case "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILED": {
      return {
        ...state,
        ChangeToDeliveryLoading: false,
        ChangetoDeliveryStatusSuccess: false,
        ChangetoDeliveryStatusFailed: true,
      };
    }
    case "CHANGE_TO_COMPLETED": {
      return {
        ...state,
        ChangeToCompletedLoading: true,
        ChangetoCompletedStatusSuccess: false,
        ChangetoCompletedStatusFailed: false,
      };
    }
    case "CHANGE_ORDER_STATUS_TO_COMPLETED_SUCCESS": {
      return {
        ...state,
        ChangeToCompletedLoading: false,
        ChangetoCompletedStatusSuccess: true,
        ChangetoCompletedStatusFailed: false,
        ChangetoCompleted: action.payload || [],
      };
    }
    case "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILED": {
      return {
        ...state,
        ChangeToCompletedLoading: false,
        ChangetoCompletedStatusSuccess: false,
        ChangetoCompletedStatusFailed: true,
      };
    }
    case "NOT_PROCESSED_SETTLEMENT_SUCCESS": {
      return {
        ...state,
        NotProcessedPaymentLoading: false,
        NotProcessedPaymentSuccess: true,
        NotProcessedPaymentData: action.payload,
        NotProcessedPaymentFailed: false,
      };
    }
    case "NOT_PROCESSED_SETTLEMENT_FAILED": {
      return {
        ...state,
        NotProcessedPaymentLoading: false,
        NotProcessedPaymentSuccess: false,
        NotProcessedPaymentFailed: true,
      };
    }
    case "COMPLETED_PAYMENT_STATUS": {
      return {
        ...state,
        CompletedPaymentLoading: true,
        CompletedPaymentSuccess: false,
        CompletedPaymentFailed: false,
      };
    }
    case "COMPLETED_SETTLEMENT_SUCCESS": {
      return {
        ...state,
        CompletedPaymentLoading: false,
        CompletedPaymentSuccess: true,
        CompletedPaymentData: action.payload,
        CompletedPaymentFailed: false,
      };
    }
    case "COMPLETED_SETTLEMENT_FAILED": {
      return {
        ...state,
        CompletedPaymentLoading: false,
        CompletedPaymentSuccess: false,
        CompletedPaymentFailed: true,
      };
    }
    case "COMPLETED_PAYMENTSEARCH_SUCCESS": {
      return {
        ...state,
        completedSearchData: true,
        completedSearchPaymentFailed: false,
        completedSearchPaymentData: action.payload.data,
      };
    }
    case "COMPLETED_PAYMENTSEARCH_FAILED": {
      return {
        ...state,
        completedSearchPaymentFailed: true,
      };
    }
    case "CHECK_SETTELEMENT_STATUS_SUCCESS": {
      return {
        ...state,
        CheckSettelementLoading: false,
        CheckSettelementSuccess: true,
        CheckSettelementData: action.payload,
        CheckSettelementFailed: false,
      };
    }
    case "CHECK_SETTELEMENT_STATUS_FAILURE": {
      return {
        ...state,
        CheckSettelementLoading: false,
        CheckSettelementSuccess: false,
        CheckSettelementFailed: true,
      };
    }
    case "UPDATE_SETTELEMENT_STATUS_SUCCESS": {
      return {
        ...state,
        UpdateSettelementLoading: false,
        UpdateSettelementSuccess: true,
        UpdateSettelementData: action.payload,
        UpdateSettelementFailed: false,
      };
    }
    case "UPDATE_SETTELEMENT_STATUS_FAILURE": {
      return {
        ...state,
        UpdateSettelementLoading: false,
        UpdateSettelementSuccess: false,
        UpdateSettelementFailed: true,
      };
    }
    case "CREATE_TRANSFER_STATUS_SUCCESS": {
      return {
        ...state,
        CreateTransferLoading: false,
        CreateTransferSuccess: true,
        CreateTransferData: action.payload.data,
        CreateTransferFailed: false,
      };
    }
    case "CREATE_TRANSFER_STATUS_FAILURE": {
      return {
        ...state,
        CreateTransferLoading: false,
        CreateTransferSuccess: false,
        CreateTransferDataError:action.payload.errors,
        CreateTransferFailed: true,
      };
    }
    case "GET_SETTINGS_SUCCESS": {
      return {
        ...state,
        SettingsLoading: false,
        SettingsSuccess: true,
        SettingsData: action.payload,
        SettingsFailed: false,
      };
    }
    case "GET_SETTINGS_FAILED": {
      return {
        ...state,
        SettingsLoading: false,
        SettingsSuccess: false,
        SettingsFailed: true,
      };
    }
    case "UPDATE_SETTINGS_LOADING": {
      return {
        ...state,
        UpdateSettingsLoading: false,
        UpdateSettingsSuccess: false,
        UpdateSettingsFailed: true,
      };
    }
    case "UPDATE_SETTINGS_SUCCESS": {
      return {
        ...state,
        UpdateSettingsLoading: false,
        UpdateSettingsSuccess: false,
        UpdateSettingsFailed: true,
      };
    }
    case "UPDATE_SETTINGS_FAILED": {
      return {
        ...state,
        UpdateSettingsLoading: false,
        UpdateSettingsSuccess: false,
        UpdateSettingsFailed: true,
      };
    }
    case "UPDATE_DELIVERYBY_STATUS_LOADING": {
      return {
        ...state,
        UpdateDeliveryByLoading: false,
        UpdateDeliveryBySuccess: false,
        UpdateDeliveryByFailed: true,
      };
    }
    case "UPDATE_DELIVERYBY_STATUS_SUCCESS": {
      return {
        ...state,
        UpdateDeliveryByLoading: false,
        UpdateDeliveryBySuccess: false,
        UpdateDeliveryByData: action.payload,
        UpdateDeliveryByFailed: true,
      };
    }
    case "UPDATE_DELIVERYBY_STATUS_FAILED": {
      return {
        ...state,
        UpdateDeliveryByLoading: false,
        UpdateDeliveryBySuccess: false,
        UpdateDeliveryByFailed: true,
      };
    }
    case "GET_LINKED_ACCOUNTS_LOADING": {
      return {
        ...state,
        getLinkedAccountsLoading: false,
        getLinkedAccountsSuccess: false,
        getLinkedAccountsFailed: true,
      };
    }
    case "GET_LINKED_ACCOUNTS_SUCCESS": {
      return {
        ...state,
        getLinkedAccountsLoading: false,
        getLinkedAccountsSuccess: false,
        getLinkedAccountsData:action.payload,
        getLinkedAccountsFailed: true,
      };
    }
    case "GET_LINKED_ACCOUNTS_FAILED": {
      return {
        ...state,
        getLinkedAccountsLoading: false,
        getLinkedAccountsSuccess: false,
        getLinkedAccountsFailed: true,
      };
    }
    case "ADD_ACCOUNT_LOADING": {
      return {
        ...state,
       AddAccountLoading: false,
       AddAccountSuccess: false,
       AddAccountFailed: true,
      };
    }
    case "ADD_ACCOUNT_SUCCESS": {
      return {
        ...state,
        AddAccountLoading: false,
        AddAccountSuccess: false,
        AddAccountData:action.payload.data,
        AddAccountFailed: true,
      };
    }
    case "ADD_ACCOUNT_FAILED": {
      return {
        ...state,
        AddAccountLoading: false,
        AddAccountSuccess: false,
        AddAccountFailed: true,
      };
    }
    default:
      return state;
  }
};
