import SignIn from "./signIn";
import {
  getSellerDetail,
  textEditChanges,
  textStoreChanges,
  checkBoxEdit,
  scheduleEdit,
  postNewUrl,
  doorDeliveryEdit,
  changeMaxDistance,
  changePreperationTime,
  changePackingCharge,
  editDeliveryCharge,
  resetDetails,
  editRetailer,
  closeSnackBars,
  handleStatusChange,
  updatestore,
  subscriptionEdit,
  subscriptionDate,
  subscriptionAmount,
  postStatusChange,
  setActiveOrder,
  rejectedStatusHandler,
  setOrder,
  setUser,
  getDashboardData,
  getNewProducts,
  getNewProductDetails,
  NewProductEditChanges,
  getDepartmentList,
  getCategoryList,
  getCategory1List,
  getCategory2List,
  addNewProduct,
  deleteNewProduct,
  getDepartments,
  AddProduct,
  AddPrdt,
  setCriteria,
  getProductSearch,
  ProductSearch,
  getProductDetails,
  setProducts,
  CreatePercentage,
  CreateFlat,
  CreateDeliveryFree,
  getUserSpecificCoupons,
  getAllCoupons,
  AssignCouponTouser,
  EditCoupon,
  ProductUpload,
  Editproductstatus,
  getBusinessType,
  ShareLink,
  setOrderDetails,
  handleShopAssistant,
  setLiveOrder,
  deleteLiveOrder,
  clearSearchOrderResult,
  getEditedLocation,
  getShopAssistantFullDetails,
  MultipleImageReset,
  MultipleImage,
  secondaryImage,
  multipleDelete,
} from "../../Redux/Action/SignInActions";
import {
  getSearchResult,
  getStoreDetails,
  editretailer,
  getSearchOrderResult,
  getOrderDetails,
  getActiveOrders,
  shopAssistantDetails,
  clearSearchOrder,
  clearSearchActiveOrder,
  setStore,
  getSearchResultByName,
  saveDetails,
  getRetailer,
  clearsearchstoresData,
  getOrderResult,
} from "../../Redux/Action/StoreDetails";
import {
  usersList,
  userDetails,
  blockUser,
  unblockUser,
} from "../../Redux/Action/UserDetails";
import {
  allComplaints,
  ComplaintDetails,
  AdminAction,
  CloseComplaint,
  clearclosedcomplaintprops,
  SolveComplaint,
  orderComplaints,
} from "../../Redux/Action/ComplaintsAction";
import {
  ChangetoActiveStatus,
  ChangetoCompletedStatus,
  ChangetoReadyStatus,
  changeToOutForDelivery,
  scheduleAlert,
  uploadOrderImage,
  getAllNotProcessedSettlements,
  getAllCompletedSettlements,
  getCompletedPaymentSearch,
  checkSettlementStatus,
  updateSettlementDetails,
  createTransfer,
  getSettings,
  updateAdminSettings,
  updateDeliveryBy,
  getExternalDeliveryPersonLinkedAccounts,
  addAccount,
} from "../../Redux/Action/OrderActions";
import { connect } from "react-redux";
import { SelectState } from "./Selector";

const mapStateToProps = (state) => {
  return { ...SelectState(state) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLiveOrder: (params) => {
      dispatch(setLiveOrder(params));
    },
    deleteLiveOrder: () => {
      dispatch(deleteLiveOrder());
    },
    getRetailer: () => {
      dispatch(getRetailer());
    },
    getStoreDetails: (params) => {
      dispatch(getStoreDetails(params));
    },
    clearsearchstoresData: () => {
      dispatch(clearsearchstoresData());
    },
    setUser: (params) => {
      dispatch(setUser(params));
    },
    setOrder: (params) => {
      dispatch(setOrder(params));
    },
    setActiveOrder: (params) => {
      dispatch(setActiveOrder(params));
    },
    postStatusChange: (params) => {
      dispatch(postStatusChange(params));
    },
    setStore: (params) => {
      dispatch(setStore(params));
    },
    getSellerDetail: (params) => {
      dispatch(getSellerDetail(params));
    },
    textEditChanges: (e, params) => {
      dispatch(textEditChanges({ e, params }));
    },
    multipleDelete: () => {
      dispatch(multipleDelete());
    },
    textStoreChanges: (e, params) => {
      dispatch(textStoreChanges({ e, params }));
    },
    handleStatusChange: (params, e) => {
      dispatch(handleStatusChange(params, e));
    },
    handleShopAssistant: (params, e) => {
      dispatch(handleShopAssistant(params, e));
    },
    checkBoxEdit: (e, params) => {
      dispatch(checkBoxEdit(e, params));
    },
    scheduleEdit: (params) => {
      dispatch(scheduleEdit(params));
    },
    postNewUrl: (params) => {
      dispatch(postNewUrl(params));
    },
    // getNewLocation: (params) => {
    //   dispatch(getNewLocation(params));
    // },
    // getNewCoordinates: (params) => {
    //   dispatch(getNewCoordinates(params));
    // },
    doorDeliveryEdit: (params) => {
      dispatch(doorDeliveryEdit(params));
    },
    changeMaxDistance: (params) => {
      dispatch(changeMaxDistance(params));
    },
    changePreperationTime: (params) => {
      dispatch(changePreperationTime(params));
    },

    changePackingCharge: (params) => {
      dispatch(changePackingCharge(params));
    },
    editDeliveryCharge: (params) => {
      dispatch(editDeliveryCharge(params));
    },
    subscriptionEdit: (params1, params2) => {
      dispatch(subscriptionEdit(params1, params2));
    },
    subscriptionDate: (params1, params2) => {
      dispatch(subscriptionDate(params1, params2));
    },
    subscriptionAmount: (params1, params2) => {
      dispatch(subscriptionAmount(params1, params2));
    },
    resetDetails: (params) => {
      dispatch(resetDetails(params));
    },
    editRetailer: (params, params1, params2) => {
      dispatch(editRetailer(params, params1, params2));
    },
    closeSnackBars: () => {
      dispatch(closeSnackBars());
    },
    getSearchResult: (params) => {
      dispatch(getSearchResult(params));
    },
    getSearchDetails: (params) => {
      dispatch(getStoreDetails(params));
    },
    getSearchOrderResult: (params) => {
      dispatch(getSearchOrderResult(params));
    },
    getOrderResult: (params) => {
      dispatch(getOrderResult(params));
    },
    clearSearchOrder: () => {
      dispatch(clearSearchOrder());
    },
    editretailer: (params1, params2) => {
      dispatch(editretailer(params1, params2));
    },
    updatestore: (params) => {
      dispatch(updatestore(params));
    },
    getOrderDetails: (params) => {
      dispatch(getOrderDetails(params));
    },
    ChangetoActiveStatus: (params1, params2) => {
      dispatch(ChangetoActiveStatus(params1, params2));
    },
    ChangetoReadyStatus: (params1, params2) => {
      dispatch(ChangetoReadyStatus(params1, params2));
    },
    changeToOutForDelivery: (params1, params2) => {
      dispatch(changeToOutForDelivery(params1, params2));
    },
    ChangetoCompletedStatus: (params1, params2) => {
      dispatch(ChangetoCompletedStatus(params1, params2));
    },
    uploadOrderImage: (params) => {
      dispatch(uploadOrderImage(params));
    },
    getAllNotProcessedSettlements: (params) => {
      dispatch(getAllNotProcessedSettlements(params));
    },
    getAllCompletedSettlements: (params) => {
      dispatch(getAllCompletedSettlements(params));
    },
    getCompletedPaymentSearch: (params) => {
      dispatch(getCompletedPaymentSearch(params));
    },
    checkSettlementStatus: (params) => {
      dispatch(checkSettlementStatus(params));
    },
    updateSettlementDetails: (params) => {
      dispatch(updateSettlementDetails(params));
    },
    createTransfer: (params) => {
      dispatch(createTransfer(params));
    },
    getSettings: (params) => {
      dispatch(getSettings(params));
    },
    updateAdminSettings: (params) => {
      dispatch(updateAdminSettings(params));
    },
    updateDeliveryBy: (params) => {
      dispatch(updateDeliveryBy(params));
    },
    getExternalDeliveryPersonLinkedAccounts: (params) => {
      dispatch(getExternalDeliveryPersonLinkedAccounts(params));
    },
    addAccount: (params) => {
      dispatch(addAccount(params));
    },
    getActiveOrders: (params) => {
      dispatch(getActiveOrders(params));
    },
    clearSearchActiveOrder: () => {
      dispatch(clearSearchActiveOrder());
    },
    shopAssistantDetails: (params) => {
      dispatch(shopAssistantDetails(params));
    },
    usersList: (params1, params2, params3, params4) => {
      dispatch(usersList(params1, params2, params3, params4));
    },
    userDetails: (params) => {
      dispatch(userDetails(params));
    },
    allComplaints: (params, params1, params2) => {
      dispatch(allComplaints(params, params1, params2));
    },
    ComplaintDetails: (params) => {
      dispatch(ComplaintDetails(params));
    },
    blockUser: (params) => {
      dispatch(blockUser(params));
    },
    unblockUser: (params) => {
      dispatch(unblockUser(params));
    },
    AdminAction: (params1, params2) => {
      dispatch(AdminAction(params1, params2));
    },
    CloseComplaint: (params1, params2) => {
      dispatch(CloseComplaint(params1, params2));
    },
    clearclosedcomplaintprops: () => {
      dispatch(clearclosedcomplaintprops());
    },
    rejectedStatusHandler: (params) => {
      dispatch(rejectedStatusHandler(params));
    },
    SolveComplaint: (params) => {
      dispatch(SolveComplaint(params));
    },
    getSearchResultByName: (params) => {
      dispatch(getSearchResultByName(params));
    },
    getDashboardData: () => {
      dispatch(getDashboardData());
    },
    orderComplaints: (params) => {
      dispatch(orderComplaints(params));
    },
    getNewProducts: () => {
      dispatch(getNewProducts());
    },
    getNewProductDetails: (params) => {
      dispatch(getNewProductDetails(params));
    },
    NewProductEditChanges: (params1, params2) => {
      dispatch(NewProductEditChanges(params1, params2));
    },
    getDepartmentList: () => {
      dispatch(getDepartmentList());
    },
    getCategoryList: (params) => {
      dispatch(getCategoryList(params));
    },
    getCategory1List: (params) => {
      dispatch(getCategory1List(params));
    },
    getCategory2List: (params) => {
      dispatch(getCategory2List(params));
    },
    addNewProduct: (params) => {
      dispatch(addNewProduct(params));
    },
    saveDetails: (params) => {
      dispatch(saveDetails(params));
    },
    deleteNewProduct: (params) => {
      dispatch(deleteNewProduct(params));
    },
    getDepartments: () => {
      dispatch(getDepartments());
    },
    AddProduct: (
      params,
      department,
      category,
      category1,
      category2,
      multipleImg,
      thumbnail
    ) => {
      dispatch(
        AddProduct(
          params,
          department,
          category,
          category1,
          category2,
          multipleImg,
          thumbnail
        )
      );
    },
    ProductUpload: (
      params,
      department,
      category,
      category1,
      category2,
      productid
    ) => {
      dispatch(
        ProductUpload(
          params,
          department,
          category,
          category1,
          category2,
          productid
        )
      );
    },
    AddPrdt: (params, department, category, category1, category2) => {
      dispatch(AddPrdt(params, department, category, category1, category2));
    },
    setCriteria: (params) => {
      dispatch(setCriteria(params));
    },
    getProductSearch: (params) => {
      dispatch(getProductSearch(params));
    },
    ProductSearch: (params) => {
      dispatch(ProductSearch(params));
    },
    getProductDetails: (params) => {
      dispatch(getProductDetails(params));
    },
    setProducts: (params) => {
      dispatch(setProducts(params));
    },
    CreatePercentage: (params) => {
      dispatch(CreatePercentage(params));
    },
    CreateFlat: (params) => {
      dispatch(CreateFlat(params));
    },
    CreateDeliveryFree: (params) => {
      dispatch(CreateDeliveryFree(params));
    },
    getUserSpecificCoupons: () => {
      dispatch(getUserSpecificCoupons());
    },
    getAllCoupons: () => {
      dispatch(getAllCoupons());
    },
    AssignCouponTouser: (params) => {
      dispatch(AssignCouponTouser(params));
    },
    EditCoupon: (params) => {
      dispatch(EditCoupon(params));
    },
    Editproductstatus: (params, params1) => {
      dispatch(Editproductstatus(params, params1));
    },
    getBusinessType: () => {
      dispatch(getBusinessType());
    },
    MultipleImage: () => {
      dispatch(MultipleImage());
    },
    MultipleImageReset: () => {
      dispatch(MultipleImageReset());
    },
    secondaryImage: (params) => {
      dispatch(secondaryImage(params));
    },
    ShareLink: (params) => {
      dispatch(ShareLink(params));
    },
    setOrderDetails: (params1, params2) => {
      dispatch(setOrderDetails(params1, params2));
    },
    scheduleAlert: (params) => {
      dispatch(scheduleAlert(params));
    },
    clearSearchOrderResult: () => {
      dispatch(clearSearchOrderResult());
    },
    getEditedLocation: (params) => {
      dispatch(getEditedLocation(params));
    },
    getShopAssistantFullDetails: (id) => {
      dispatch(getShopAssistantFullDetails(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
