import client from "../../../Configurations/apollo";
import {
	GET_STORE_SEARCH,
	GET_STORE_DETAILS,
	UPDATE_RETAILER,
	GET_ORDER_SEARCH,
	GET_ORDER_DETAILS,
	GET_ACTIVE_ORDERS,
	SHOP_ASSISTANT_DETAILS,
	GET_STORE_SEARCH_BY_NAME,
	GET_RETAILER_DETAIL,
	ORDER_SEARCH
} from "./gqlQuery";

export const getSearchResult = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "SEARCHING"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_ERROR" });
		}
		const success = await client.query({
			query: GET_STORE_SEARCH,
			variables: {                
                pagination: { offset: params.offset, first: 20 },
                sellerEmail: params.emailAddress?.toLowerCase(),
                sellerMobile: params.mobileNumber,
                status: params.status,

			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const clearsearchstoresData = () =>{
	return {
		type: "CLEAR_STORE_SEARCH_RESULTS",
	};
};
export const setStore = (params) => {
	let newStores = { params };
	
	return {
		type: "Store_Search",
		payload: {
			
					newStores,
		},
	};
};


export const getSearchOrderResult = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "SEARCHING_ORDER"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_ORDER_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_ORDER_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_ORDER_ERROR" });
		}
		const success = await client.query({
			query: GET_ORDER_SEARCH,
			variables: {                
                pagination: { offset: params.offset, first: params.first },
				storeid: params.storeid,
				orderNumber: params.orderNumber?Number(params.orderNumber):null,
				customerMobile: params.customerMobile,
				orderstatus: params.orderstatus,
				dateFrom: params.dateFrom,
				dateTo: params.dateTo,
				deliveryDateFrom: params.deliveryDateFrom,
				deliveryDateTo: params.deliveryDateTo
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};
export const clearSearchOrder = () =>{
	return async (dispatch) => {
		dispatch({ type: "CLEAR_SEARCH_ORDER"});
	}
};
export const getRetailer = () => {
	// console.log("poi",params);
	return async (dispatch) => {
		dispatch({ type: "SEARCHING_STORE_DETAILS"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_RETAILER_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_RETAILER_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_RETAILER_ERROR" });
		}
		const success = await client.query({
			query: GET_RETAILER_DETAIL,
			variables: {
				retailerid:`${localStorage.getItem('retailerid')}`
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};
export const getStoreDetails = (params) => {
	
	return async (dispatch) => {
		dispatch({ type: "SEARCHING_STORE_DETAILS"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_DETAILS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_DETAILS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_DETAILS_ERROR" });
		}
		const success = await client.query({
			query: GET_STORE_DETAILS,
			variables: {
				storeid:`${params}`
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};
export const editretailer = (params) => {
	return (dispatch) => {
		dispatch({
			type: "UPDATE_RETAILER",
		});
		client
			.mutate({
				mutation: UPDATE_RETAILER,
				variables: {
					// orderid: params.orderid,

					retailerid:`${localStorage.getItem('retailerid')}`,
					firstName:params.firstName,
					lastName:params.lastName,
					mobileNumber:params.mobileNumber,
					secondaryNumber:params.secondaryNumber,
				
					PANnumber:params.PANnumber,
					GSTnumber:params.GSTnumber,
					aadharNumber:params.aadharNumber,
					// stats: { status: params.status },
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "EDIT_RETAILER_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "EDIT_RETAILER_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "EDIT_RETAILER_FAILED",
					payload: err.message,
				});
			});
	};
};
export const getOrderDetails = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_ORDER_DETAILS"});
		function onSuccess(success) {
			dispatch({ type: "ORDER_DETAILS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "ORDER_DETAILS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "ORDER_DETAILS_ERROR" });
		}
		const success = await client.query({
			query: GET_ORDER_DETAILS,
			variables: {                
                orderid: params,
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};
export const getActiveOrders = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "SEARCHING_ACTIVEORDER"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_ACTIVEORDER_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_ACTIVEORDER_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_ACTIVEORDER_ERROR" });
		}
		const success = await client.query({
			query: GET_ACTIVE_ORDERS,
			variables: {                
                pagination: { offset: params.offset, first:5 },
				orderstatus: params.orderstatus,
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};
export const clearSearchActiveOrder = () =>{
	return async (dispatch) => {
		dispatch({ type: "CLEAR_SEARCH_ACTIVE_ORDER"});
	}
};
export const shopAssistantDetails = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_SHOPASSISTANTS"});
		function onSuccess(success) {
			dispatch({ type: "SHOPASSISTANTS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SHOPASSISTANTS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SHOPASSISTANTS_ERROR" });
		}
		const success = await client.query({
			query: SHOP_ASSISTANT_DETAILS,
			variables: {                
				shopAssistantId:params
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const getSearchResultByName = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "SEARCHING"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_ERROR" });
		}
		const success = await client.query({
			query: GET_STORE_SEARCH_BY_NAME,
			variables: {                
               searchKey:params
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const saveDetails = (params) => {
	console.log("this is from save details----",params)
	return {
		 type: "SAVE_DETAILS",
		 payload:params
		};
};


export const getOrderResult = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "SEARCHING_ORDER"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_ORDER_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_ORDER_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_ORDER_ERROR" });
		}
		const success = await client.query({
			query: ORDER_SEARCH,
			variables: {                
                pagination: { offset: params?.offset, first: params?.first },
				storeid: params?.storeid,
				orderNumber: params?.orderNumber && Number(params?.orderNumber),
				customerMobile: params?.customerMobile,
				orderstatus: params?.orderstatus,
				dateFrom: params?.dateFrom,
				dateTo: params?.dateTo,
				deliveryDateFrom: params?.deliveryDateFrom,
				deliveryDateTo: params?.deliveryDateTo
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};