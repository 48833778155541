import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import SearchActiveOrderResults from "../SearchActiveOrderResults";
import { Toolbar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./index.css";

class ActiveOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderstatus: "",
      first: 10,
      offset: 0,
      orders: [],
      hasNext:true
    };
  }
  componentDidMount() {
    this.props.clearSearchActiveOrder()
  }
 
  render() {
    const statusFilters = [
      { option: "Order-Placed", value: "Order-Placed" },
      //{ option: "Order-Accepted", value: "Order-Accepted" },
      { option: "Shopping-In-Progress", value: "Shopping-In-Progress" },
      { option: "Order-Ready", value: "Order-Ready" },
      // { option: "Order-Rejected", value: "Order-Rejected" },
      // { option: "Order-Cancelled", value: "Order-Cancelled" },
      
       { option: "Out-For-Delivery", value: "Out-For-Delivery" },
      // { option: "Order-Completed", value: "Completed" },
    ];
    const loadingModal = (
      <div style={{ justifyContent: "center" }}>
        <CircularProgress style={{ color: "grey" }} />
      </div>
    );
    const loadPrevious = () => {
      this.setState({
        ...this.state,
        offset: this.state.offset - 10,
      },
        () => {
          this.props.getActiveOrders({ ...this.state });
        })

    };
    const loadMore = () => {
      this.setState({
        ...this.state,
        offset: this.state.offset + 10,
      },
        () => {
          this.props.getActiveOrders({ ...this.state });
        })

    };
    const handleSearchField = (e) => {
      let { value } = e.target;
      this.setState({
        ...this.state,
        [e.target.name]: value,
      });
    };

    const searchOrderHandle = () => {
      this.setState({
        orders: [],
        offset: 0,
      }, () => {
        this.props.clearSearchActiveOrder();
        if (this.state.status !== null) {
          this.props.getActiveOrders(
            this.state);
        } else {
          this.props.getSearchOrderResult({ ...this.state });
        }
      });
    };
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            transform: "translateY(100px)",
            width: "98%",
            borderRadius: "30px",
            padding: '15px',
            boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
          }}
          position="sticky"
        >
          <Toolbar
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                fontWeight: 600,
                textAlign: "center",
                color: "black",
                textDecoration: "underline",
              }}
            >
              {"Active Orders"}
            </h3>
          </Toolbar>

          <Grid container spacing={3} sx={{ padding: { md: '100px', xs: '20px' }, width: '100%' }}>
            <Grid item xs={12} md={6} style={{ width: '100%' }}>
              <FormControl style={{ width: "100%" }} variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  fullWidth
                  onChange={handleSearchField}
                  value={this.state.orderstatus}
                  name="orderstatus"
                  label="Status"
                  MenuProps={{ style: { marginTop: '40px' } }}

                >
                  {statusFilters.map((statusFilter, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={{ width: '100%' }}
                        value={statusFilter.value}
                      >
                        {console.log("stats",statusFilter.option)}
                        {statusFilter.option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <button
                className="searchBtn"
                type="button"
                onClick={() => {
                  searchOrderHandle();
                }}
              >
                Search Order
              </button>
            </Grid>
          </Grid>
          {this.props.searchingactiveorder && loadingModal}
          {this.props.searchActiveOrderSuccess ? <SearchActiveOrderResults
            ActiveOrder={this.props.ActiveOrders}
            orderstatus={this.state.orderstatus} {...this.props} /> : null}
          <div style={{ dispaly: 'flex', width: '100%', margin: '15px 0', boxSizing: 'border-box' }}>
            <span style={{ width: '50%', float: 'left', justifyContent: 'left', alignItems: 'left', textAlign: 'left' }}>
              {this.state.offset > 0 &&
                <button
                  style={{
                    padding: '10px',
                    color: 'rgb(255, 255, 255)',
                    backgroundImage: 'linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
                    boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                    height: '100%',
                    fontSize: '18px',
                    fontWeight: 600,
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                    overflow: 'hidden',
                    outline: 'none',
                    fontFamily: ' Varela Round, sans-serif',
                    textTransform: 'none',
                    textAign: 'center',
                    width: '40%',
                    cursor: 'pointer',
                    boxSizing: 'border-box'
                  }}
                  onClick={loadPrevious}>
                  Previous
                </button>
              }
            </span>
            <span
              style={{
                width: '50%',
                float: 'right',
                justifyContent: 'right',
                alignItems: 'right',
                textAlign: 'right'
              }}>
              {this.props.searchActiveOrderData &&
                this.props.searchActiveOrderData &&
                this.props.searchActiveOrderData.hasNext &&
                this.props.searchActiveOrderData.hasNext === true &&
                <button
                  style={{
                    padding: '10px',
                    backgroundImage: 'linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
                    boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                    height: '100%',
                    fontSize: '18px',
                    fontWeight: 600,
                    borderRadius: '5px',
                    border: 'none',
                    overflow: 'hidden',
                    outline: 'none',
                    fontFamily: ' Varela Round, sans-serif',
                    textTransform: 'none',
                    textAign: 'center',
                    width: '40%',
                    cursor: 'pointer',
                    color: '#ffffff',
                    boxSizing: 'border-box'
                  }}
                  onClick={loadMore}>
                  Next
                </button>
              }
            </span>
          </div>
        </div>
      </>
    );
  }
}
export default ActiveOrders;
