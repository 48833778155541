import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import './index.css';

export default function RetailerForm(props) {
  React.useEffect(() => {
    document.body.scrollTop = 0;
  }
    , [])

  const retailerLabels = [
    "First Name",
    "Last Name",
    "Mobile Number",
    "Secondary Number",
    "Email Address",
    "PAN Number",
    "GST Number",
    "Aadhar Number",
    "fssaiNo",
  ];
 // console.log("forAdmin",forAdmin)
 const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter First Name"),
  lastName: Yup.string().required("Please enter Last Name"),
  mobileNumber: Yup.string()
    .trim("Space not allowed")
    .matches(/^[0-9]*$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number")
    .required("Please enter Mobile number"),
  secondaryNumber: Yup.string()
    .trim("Space not allowed")
    .matches(/^[0-9]*$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number"),
  emailAddress: Yup.string().email().required("Please enter Email Address"),
  PANnumber: Yup.string()
    .trim()
    .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Please enter valid PAN number")
    .max(10, "Please enter valid PAN number"),
  VATnumber: Yup.string().trim("Space not allowed"),
  GSTnumber: Yup.string()
    .matches(/[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Please enter valid GST number")
    .trim("Space not allowed"),
  aadharNumber: Yup.string()
    .matches(/^[0-9]{12}$/, "Please enter a valid Aadhar number with exactly 12 digits")
    .trim("Spaces are not allowed"),
    fssaiNo: Yup.string()
    .matches(/^[0-9]{14}$/, "Please enter a valid FSSAI number with exactly 14 digits")
    .trim("Spaces are not allowed")
    
});

  const formik = useFormik({
    initialValues: {
      firstName: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.firstName &&
        props.searchDetails.store.forAdmin.firstName) || "",
      lastName: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.lastName &&
        props.searchDetails.store.forAdmin.lastName) || "",
      mobileNumber: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.mobileNumber &&
        props.searchDetails.store.forAdmin.mobileNumber) || "",
      secondaryNumber: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.secondaryNumber &&
        props.searchDetails.store.forAdmin.secondaryNumber) || "",
      emailAddress: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.emailAddress &&
        props.searchDetails.store.forAdmin.emailAddress) || "",
      GSTnumber: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.GSTnumber &&
        props.searchDetails.store.forAdmin.GSTnumber) || "",
      PANnumber: (props.searchDetails &&
        props.searchDetails.store &&
        props.searchDetails.store.forAdmin &&
        props.searchDetails.store.forAdmin.PANnumber &&
        props.searchDetails.store.forAdmin.PANnumber) || "",
        aadharNumber:(props.searchDetails &&
          props.searchDetails.store &&
          props.searchDetails.store.forAdmin &&
          props.searchDetails.store.forAdmin.aadharNumber &&
          props.searchDetails.store.forAdmin.aadharNumber) || "",
        fssaiNumber:(props.searchDetails &&
            props.searchDetails.store &&
            props.searchDetails.store.forAdmin &&
            props.searchDetails.store.fssaiNo &&
            props.searchDetails.store.fssaiNo) || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.mobileNumber === values.secondaryNumber) {
        formik.setFieldError('secondaryNumber', 'Secondary number and mobile number should not be the same')
      }
      else {
        props.editRetailer(localStorage.getItem('retailerid'), values, props.searchDetails.store);
        props.next();
      }
    },
  });


  let retailerDetails =
    props.searchDetails &&
    props.searchDetails.store &&
    Object.keys(props.searchDetails.store.retailer);

  let retailerTextField = [
    "firstName",
    "lastName",
    "mobileNumber",
    "secondaryNumber",
    "emailAddress",
    "PANnumber",
    "GSTnumber",
    "aadharNumber",
    "fssaiNo",
  ];
  return (
    <Container>
      <div style={{ fontWeight: 600 }}>
        <h4 style={{ paddingTop: '30px', fontWeight: 500, textAlign: "center", color: 'black', textDecoration: 'underline' }}>
          {"Retailer Details"}
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <ul>
            <Row>
              {retailerDetails
                ? retailerTextField.map((detail, index) =>
                  index < 8 ? (
                    <Col md={6} key={index} style={{ paddingTop: 30 }}>
                      <TextField
                        disabled={retailerLabels[index] === "Email Address" ? true : false}
                        InputProps={{ style: { height: 50, borderRadius: '15px' } }}
                        id="filled-read-only-input"
                        label={retailerLabels[index]}
                        background="red"
                        name={detail}
                        value={formik.values[detail]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[detail] && Boolean(formik.errors[detail])
                        }
                        helperText={formik.touched[detail] && formik.errors[detail]}
                        variant="outlined"
                        fullWidth
                      />
                    </Col>
                  ) : null
                )
                : null}
            </Row>
          </ul>
          <div className='buttons'>
            <button type='submit' className='nextbutton' id='nex'>
              {'Save & Next'}
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
}
