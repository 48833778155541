import {
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./index.css";
import CustomTextField from "../CommonComponents/input";
import SearchIcon from "../utils/Icon/searchcopy.svg";
import CustomCard from "../CommonComponents/card";
import Arrow from "../utils/Icon/Arrow.svg";
import { SpaRounded } from "@material-ui/icons";
import CustomButton from "../CommonComponents/button";
import CustomBadge from "../CommonComponents/badge";
import PaymentTwo from "./PaymentTwo";
import PaymentOne from "./PaymentOne";
import Completed from "./Completed";
import { HeaderNavigationFontSize_20 } from "../Typography";
import { first } from "lodash";

function Payment(props) {
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [orderSearch, setOrderSearch] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [first, setFirst] = React.useState(10);
  const [offset, setOffset] = React.useState(0);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  useEffect(() => {
    setOrderSearch(true);
    if (search === "") {
      props.getAllNotProcessedSettlements({ offset: offset, first: first });
    } else if (search.length === 5) {
      const searchAsFloat = parseFloat(search);
      props.getAllNotProcessedSettlements({ orderNumber: searchAsFloat });
    }
  }, [search]);
  const [state, setState] = useState("Settlements In-Progress");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);

  function handleClickTwo() {
    props.history.push({
      pathname: `/home/Payment/Completed`,
    });
  }
  function handleClickOne() {}

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          transform: "translateY(100px)",
          width: "100%",
          borderRadius: "30px",
          height: "auto",
          padding: "15px",
          boxShadow:
            "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        }}
        position="sticky"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div onClick={handleClickOne} style={{ cursor: "pointer" }}>
            <Typography
              sx={{
                color: one ? "#85BE49" : "#8B8989",
                fontFamily: "Roboto",
                fontWeight: "600",
                fontSize: "16px",
                textDecoration: one ? "underline" : "none",
                textUnderlineOffset: one ? "4px" : "0px",
              }}
            >
              Non Processed Transactions
            </Typography>
          </div>
          <div onClick={handleClickTwo} style={{ cursor: "pointer" }}>
            <Typography
              sx={{
                color: two ? "#85BE49" : "#8B8989",
                fontFamily: "Roboto",
                fontWeight: "600",
                fontSize: "16px",
                textDecoration: two ? "underline" : "none",
                textUnderlineOffset: two ? "4px" : "0px",
              }}
            >
              Completed
            </Typography>
          </div>
        </div>
        <div className="add-txt-orderHistory">
          <TextField
            placeholder="Search"
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            value={search}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D1D5DB",
                },
                borderRadius: "20px",
                "&.Mui-focused fieldset": {
                  borderColor: "#D1D5DB",
                },
              },
              "& .MuiInputBase-input": {
                backgroundColor: "transparent",
                borderRadius: "20px",
              },
            }}
            InputProps={{
              sx: {
                fontSize: matches && "13px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={SearchIcon}
                    alt="Search"
                    style={{ marginRight: "8px" }}
                  />
                </InputAdornment>
              ),
            }}
            size={matches ? "small" : "medium"}
          />
        </div>
        {<PaymentOne {...props} />}
        {/* <div>
          <CustomButton >Load More</CustomButton>
        </div> */}
        {/* {loading && (
          <div
            style={{
              marginTop: "8%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={HeaderNavigationFontSize_20}
              className="Add-product-btn"
            >
              Loading...
            </Typography>
          </div>
        )} */}
      </div>
      {props?.NotProcessedPaymentData?.data?.listAllNotProcessedSettlements
        ?.count === 0 && (
        <div
          style={{
            marginTop: "8%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            fontSize={HeaderNavigationFontSize_20}
            className="Add-product-btn"
          >
            No settlement in progress.
          </Typography>
        </div>
      )}
      {!loading &&
        orderSearch &&
        search.length >= 5 &&
        orderList.length === 0 && (
          <div
            style={{
              marginTop: "8%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={HeaderNavigationFontSize_20}
              className="Add-product-btn"
            >
              No Orders Found.
            </Typography>
          </div>
        )}
    </>
  );
}

export default Payment;
