import { React, useEffect, useState } from "react";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  contentFontSize_18,
  contentFontSize_20,
  HeaderFontSize_25,
  HeaderFontSize_24,
  contentFontSize_22,
  contentFontsize_14_xs_10,
} from "../Typography/index";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  InputAdornment,
  //makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Checkbox,
  FormControlLabel,
  DialogContent,
} from "@mui/material";
import "./PendingView";
import { useLocation } from "react-router-dom";
import Amount from "../utils/Icon/Amount.svg";
import closeIconPayment from "../utils/Icon/closeIconPayment.png";
import PaymentIconImage from "../utils/Icon/PaymentIconImage.png";
import copyPayment from "../utils/Icon/copyPayment.png";
import deliver from "../utils/Icon/scooter.svg";
import payment from "../utils/Icon/paymentmethod.svg";
import edit from "../utils/Icon/Edit1.svg";
import save from "../utils/Icon/Save.svg";
import CustomCard from "../CommonComponents/card";
import Direction from "../utils/Icon/buttontick.svg";
import CustomButton from "../CommonComponents/button";
import { CheckBox } from "@material-ui/icons";
import "./view.css";

function View(props) {
  const [checked, setChecked] = useState(false);
  const [sellerTransfer, setSellerTransfer] = useState(false);
  const [sellerEdit, setSellerEdit] = useState(false);
  const [nearshopzTransfer, setNearshopzTransfer] = useState(false);
  const [nearshopzEdit, setNearshopzEdit] = useState(false);
  const [externalTransfer, setExternalTransfer] = useState(false);
  const [externalEdit, setExternalEdit] = useState(false);
  const [sellerReimbursement, setSellerReimbursement] = useState(false);
  const [reimbursementEdit, setReimbursementEdit] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [comment, setComment] = useState("");

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  const location = useLocation();
  const { state } = location;
  const orderList = state?.orderList;

  const handleCommentClick = () => {
    props.updateSettlementDetails({
      orderid: orderList.id,
      settlementComment: comment,
    });
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const [transferComplete, setTransferComplete] = useState(false);

  const handleTransferClick = () => {
    setTransferComplete(true);
  };

  useEffect(() => {
    if (orderList.deliveryBy === 1 && orderList.paymentStatus != 1) {
      setSellerTransfer(true);
    }
    if (orderList.deliveryBy === 2) {
      setNearshopzTransfer(true);
    }
    if (orderList.deliveryBy === 3) {
      setExternalTransfer(true);
    }
    if (orderList.deliveryBy === 1) {
      if (orderList.paymentStatus === 1) {
        setSellerReimbursement(true);
      }
    }
  }, [orderList]);

  console.log("Order List:", orderList);

  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    props.history.push({
      pathname: `/home/Payment/Completed`,
    });
  };

  const handleDialogClose = () => {
    setTransferComplete(false);
  };

  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "white",
        transform: "translateY(100px)",
        width: "100%",
        borderRadius: "30px",
        height: "auto",
        padding: "15px",
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      }}
      position="sticky"
    >
      <div style={{ display: "flex" }}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={handleClose}
          className="header-font"
          fontSize={HeaderNavigationFontSize_20}
        >
          Completed
        </Typography>
        <Typography
          className="header-font"
          fontSize={HeaderNavigationFontSize_20}
        >
          /
        </Typography>
        <Typography
          className="header-font"
          fontSize={HeaderNavigationFontSize_20}
          style={{ color: "#85BE49" }}
        >
          View
        </Typography>
      </div>
      <div style={{ paddingTop: "20px", boxSizing: "border-box" }}>
        {/* {orderList?.map((order, index) => ( */}
        <Grid sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
          <CustomCard
            padding={"30px"}
            width="100%"
            height="120px"
            background="#F4F5F9"
            sx={{ display: "flex", alignItems: "center" }}
            boxShadow="none"
          >
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ paddingLeft: "0px", paddingTop: "0px" }}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      className="Card-text"
                      fontSize={contentFontSize_20}
                      color={"#2D93FB"}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                      // onClick={() => handleOrder(order.id)}
                    >
                      #{orderList?.orderNumber}
                    </Typography>
                    <Typography
                      fontSize={contentFontsize_14}
                      // className="Card-subtext"
                    >
                      {orderList?.transferSettlement[0]?.created}{" "}
                      {orderList?.transferSettlement[0]?.createdTime}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <img src={Amount} alt="Amount" />
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          color: "#13801C",
                        }}
                      >
                        Amount
                      </Typography>
                    </div>

                    {/* Centering the Amount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "36%",
                      }}
                    >
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      >
                        ₹{" "}
                        {orderList?.totalPayable
                          ? orderList.totalPayable.toFixed(2)
                          : "0.00"}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                {/* Adjusted status Grid item */}
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <img src={deliver} alt="Amount" />
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          color: "#13801C",
                        }}
                      >
                        Delivered By
                      </Typography>
                    </div>

                    {/* Centering the Amount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      >
                        {orderList?.deliveryBy === 1
                          ? "Seller"
                          : orderList?.deliveryBy === 2
                          ? "Nearshopz"
                          : orderList?.deliveryBy === 3
                          ? "External Delivery Person"
                          : "Unknown"}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <img src={payment} alt="Amount" />

                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          color: "#13801C",
                        }}
                      >
                        Payment Method
                      </Typography>
                    </div>

                    {/* Centering the Amount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // width: "50%",
                      }}
                    >
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      >
                       {orderList?.paymentStatus === 1
                          ? "COD"
                          : orderList?.paymentStatus === 2
                          ? "Online Payment"
                          : "COD/Online Payment"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </CustomCard>
        </Grid>
        {/* ))} */}
      </div>
      <div style={{ padding: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div>
            <Typography
              fontSize={contentFontSize_20}
              sx={{
                fontFamily: "Roboto",
                fontWeight: "600",
                color: "black",
                textAlign: "left",
              }}
            >
              ORDER PAYMENT BREAKDOWN 
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {/* <img src={edit} />
            <img src={save} /> */}
          </div>
        </div>
        <div>
          {/* Header Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "30px 0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography style={{ fontWeight: 600 }}></Typography>
            </div>
            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 600 }}>
                Original Price
              </Typography>
            </div>
            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 600, color: "#D40000" }}>
                Revised Price
              </Typography>
            </div>
            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 600 }}>Difference</Typography>
            </div>
            <div style={{ flex: 1 }}>
              <Typography style={{ fontWeight: 600, textAlign: "center" }}>
                Action
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Seller Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {orderList?.amountForSeller.toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedAmountForSeller === 0 ||
                  orderList?.editedAmountForSeller == null
                    ? (orderList?.amountForSeller).toFixed(2)
                    : (orderList?.editedAmountForSeller).toFixed(2)
                }
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedAmountForSeller != null
                  ? orderList?.amountForSeller -
                    orderList?.editedAmountForSeller
                  : orderList?.amountForSeller - orderList?.amountForSeller
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {sellerTransfer && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                    onClick={handleTransferClick}
                  />
                  Transfered
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                NearShopz Delivery <br />
                Partner's Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{(orderList?.amountForNearshopzDeliveryPerson).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedAmountForNearshopzDeliveryPerson === 0 ||
                  orderList?.editedAmountForNearshopzDeliveryPerson == null
                    ? (orderList?.amountForNearshopzDeliveryPerson).toFixed(2)
                    : (orderList?.editedAmountForNearshopzDeliveryPerson).toFixed(
                        2
                      )
                }
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedAmountForNearshopzDeliveryPerson != null
                  ? orderList?.amountForNearshopzDeliveryPerson -
                    orderList?.editedAmountForNearshopzDeliveryPerson
                  : orderList?.amountForNearshopzDeliveryPerson -
                    orderList?.amountForNearshopzDeliveryPerson
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {nearshopzTransfer && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                  />
                  Transfered
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                External Delivery Partner's Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.amountForExternalDeliveryPerson).toFixed(2)}{" "}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedAmountForExternalDeliveryPerson === 0 ||
                  orderList?.editedAmountForExternalDeliveryPerson == null
                    ? (orderList?.amountForExternalDeliveryPerson).toFixed(2)
                    : (orderList?.editedAmountForExternalDeliveryPerson).toFixed(
                        2
                      )
                }
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedAmountForExternalDeliveryPerson != null
                  ? orderList?.amountForExternalDeliveryPerson -
                    orderList?.editedAmountForExternalDeliveryPerson
                  : orderList?.amountForExternalDeliveryPerson -
                    orderList?.amountForExternalDeliveryPerson
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {externalTransfer && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                  />
                  Transfered
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Refund for Customer
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.amountForCustomer).toFixed(2)}{" "}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={(orderList?.amountForCustomer ?? 0).toFixed(2)}
                disabled
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(
                  orderList?.amountForCustomer - orderList?.amountForCustomer ??
                  0
                ).toFixed(2)}
              </Typography>
            </div>

            <div
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Razor Pay Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.razorPayFeesAndTax ?? 0).toFixed(2)}{" "}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedRazorPayFeesAndTax === 0 ||
                  orderList?.editedRazorPayFeesAndTax == null
                    ? (orderList?.razorPayFeesAndTax ?? 0).toFixed(2)
                    : (orderList?.editedRazorPayFeesAndTax ?? 0).toFixed(2)
                }
                disabled
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedRazorPayFeesAndTax != null
                  ? orderList?.razorPayFeesAndTax -
                    orderList?.editedRazorPayFeesAndTax
                  : orderList?.razorPayFeesAndTax -
                    orderList?.razorPayFeesAndTax
                ).toFixed(2)}
              </Typography>
            </div>

            <div
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                NearShopz
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.amountForNearshopz).toFixed(2)}{" "}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedAmountForNearshopz === 0 ||
                  orderList?.editedAmountForNearshopz == null
                    ? (orderList?.amountForNearshopz ?? 0).toFixed(2)
                    : (orderList?.editedAmountForNearshopz ?? 0).toFixed(2)
                }
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedAmountForNearshopz != null
                  ? orderList?.amountForNearshopz -
                    orderList?.editedAmountForNearshopz
                  : orderList?.amountForNearshopz -
                    orderList?.amountForNearshopz
                ).toFixed(2)}
              </Typography>
            </div>

            <div
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Seller Reimbursement
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.sellerReimbursement ?? 0).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.sellerReimbursement === 0 ||
                  orderList?.sellerReimbursement == null
                    ? (orderList?.editedSellerReimbursement ?? 0).toFixed(2)
                    : (orderList?.sellerReimbursement ?? 0).toFixed(2)
                }
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedSellerReimbursement != null
                  ? orderList?.sellerReimbursement -
                    orderList?.editedSellerReimbursement
                  : orderList?.sellerReimbursement -
                    orderList?.sellerReimbursement
                ).toFixed(2)}
              </Typography>
            </div>

            <div
              style={{ flex: 1, display: "flex", justifyContent: "center" }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              // borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "#D40000" }}
              >
                Extra Amount By Nearshopz
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.extraAmountByNearshopz ?? 0).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedExtraAmountByNearshopz === 0 ||
                  orderList?.editedExtraAmountByNearshopz == null
                    ? (orderList?.extraAmountByNearshopz).toFixed(2)
                    : (orderList?.editedExtraAmountByNearshopz).toFixed(2)
                }
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedExtraAmountByNearshopz != null
                  ? orderList?.extraAmountByNearshopz -
                    orderList?.editedExtraAmountByNearshopz
                  : orderList?.extraAmountByNearshopz -
                    orderList?.extraAmountByNearshopz
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {/* <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                width="120px"
              >
                Transfer
              </CustomButton> */}
            </div>
          </div>
          <div style={{ paddingTop: "30px" }}>
            <Typography
              style={{
                textAlign: "left",
                color: "black",
                marginBottom: "10px",
                fontWeight: "600",
              }}
              fontSize={contentFontSize_18}
            >
              Comments
            </Typography>
            <TextField
              name="message"
              multiline={true}
              rows="3"
              value={comment}
              onChange={handleCommentChange}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#BDBABA",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#BDBABA",
                  },
                },
                "& .MuiInputBase-input": {
                  // borderRadius: "20px",
                },
              }}
            ></TextField>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                borderRadius="10px"
                width="160px"
                onClick={handleCommentClick}
              >
                <Typography
                  fontSize={contentFontSize_16}
                  style={{ textTransform: "none" }}
                >
                  Save Comments
                </Typography>
              </CustomButton>
            </div>
            {/* <div style={{ marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <Box
                        sx={{
                          width: 27,
                          height: 27,
                          border: "1px solid black",
                          borderRadius: "4px",
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 27,
                          height: 27,
                          border: "1px solid #D9D9D9",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <Box
                          sx={{
                            width: 18,
                            height: 9,
                            borderLeft: "2px solid white",
                            borderBottom: "2px solid white",
                            transform: "rotate(-45deg)",
                          }}
                        />
                      </Box>
                    }
                    checked={checked}
                    onChange={handleCheckboxChange}
                    sx={{
                      color: "white",
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "600",
                      color: "black",
                      marginLeft: "10px",
                    }}
                    fontSize={contentFontSize_18}
                  >
                    Admin requests order closure as all payments are settled.
                  </Typography>
                }
              />
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px 0 30px 0",
              // borderBottom: "1px solid #e0e0e0",
            }}
          >
            <CustomButton
              variant="contained"
              style={{ backgroundColor: "#4CAF50", color: "white" }}
              width="30%"
              borderRadius="15px"
              onClick={handleClose}
            >
              <Typography
                fontSize={ButtonFontSize_18}
                style={{ textTransform: "none", fontWeight: "500" }}
              >
                Back
              </Typography>
            </CustomButton>
          </div>
        </div>
      </div>

      <Dialog
        open={transferComplete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              padding: "20px 0px 0px 0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "98%",
                top: "-5px",
                cursor: "pointer",
              }}
              onClick={handleDialogClose}
            >
              <img src={closeIconPayment} alt="closeIcon" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                style={{ fontFamily: "Roboto", fontWeight: "700" }}
                fontSize={contentFontSize_22}
              >
                ₹
                {orderList?.totalPayable
                  ? orderList.totalPayable.toFixed(2)
                  : "0.00"}
              </Typography>
              <Typography
                style={{ fontFamily: "Roboto", fontWeight: "500" }}
                fontSize={contentFontsize_14_xs_10}
              >
                autopay by razor pay/03245676543
              </Typography>
            </div>
            <div
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                padding: "20px",
                marginTop: "35px",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                fontSize={contentFontSize_18}
              >
                Transaction Details
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Transaction ID
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    S6578654 <span style={{ margin: "0 1px" }}></span>
                    <img src={copyPayment} alt="no image" />
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Status
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    {orderList?.transferSettlement?.length > 0
                      ? orderList.transferSettlement[0].status
                      : "No status available"}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Transaction Type
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Credit
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Date & Time
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    {orderList?.transferSettlement?.[0]?.created || "N/A"} |{" "}
                    {orderList?.transferSettlement?.[0]?.createdTime || "N/A"}
                  </Typography>
                </div>
              </div>

              <div style={{ position: "absolute", top: "-7%", left: "42%" }}>
                <img src={PaymentIconImage} alt="paymentIcon" />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default View;
