import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '../../MUIComponents/index';

const CustomBadge = ({
  children,
  badgeContent,
  onClick,
  color,
  backgroundColor,
  borderColor,
  border, 
  fontSize,
  minWidth,
  height,
  marginLeft,
  
  
}) => {
  const badgeStyle = {
    "& .MuiBadge-badge": {
      fontSize: {fontSize},
      color: color,  
      backgroundColor: backgroundColor, 
      borderColor: borderColor,  
      border: border || (borderColor ? `2px solid ${borderColor}` : '2px solid white'),
    },
    "& .css-1ikkfg1-MuiBadge-badge ":{
      height:{height},
      minWidth:{minWidth},
      borderRadius:"50%",
      padding:"0px",
      boxSizing:"borderBox",
    }
  };
 
  
  return (
    <Badge
      sx={badgeStyle}
      style={{ marginLeft: marginLeft }}
      onClick={onClick}
      badgeContent={badgeContent}
     
    >
      {children}
    </Badge>
  );
};

CustomBadge.propTypes = {
  badgeContent: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  color:PropTypes.string,
  backgroundColor:PropTypes.string,
  borderColor:PropTypes.string,
  border:PropTypes.string,
 fontSize:PropTypes.string,
 minWidth:PropTypes.string,
 height:PropTypes.string,
 marginLeft:PropTypes.string,

};

CustomBadge.defaultProps = {
  onClick: () => {},
};

export default CustomBadge;
