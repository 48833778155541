import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});
const EditDeliveryCharge = (props) => {
  const charges = [
    { label: "0-3 Km", distanceValue: 3, value: null },
    { label: "3-6 Km", distanceValue: 6, value: null },
    { label: "6-9 Km", distanceValue: 9, value: null },
    { label: "9-12 Km", distanceValue: 12, value: null },
    { label: "12-15 Km", distanceValue: 15, value: null },
  ];

  const classes = useStyles();
  return (
    <TableContainer component={Paper} style={{ paddingTop: 20 }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Km from store</TableCell>
            <TableCell align="right">Charges(in Rupees)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {charges.map((row, index) =>
            row.distanceValue <= props.distance ? (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    index={index}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      props.editDeliveryCharge({
                        value: e.target.value,
                        index: index,
                        details: props?.searchDetails?.store,
                      });
                    }}
                    name={row.label}
                    value={
                      props?.searchDetails &&
                      props?.searchDetails?.store &&
                      props?.searchDetails?.store?.deliveryCharge &&
                      props?.searchDetails?.store?.deliveryCharge[index]?.charge
                    }
                  />
                </TableCell>
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditDeliveryCharge;