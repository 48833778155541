// import React, { useState } from "react";
// import {Link} from 'react-router-dom'
// import { Container, Row, Col, Button } from "react-bootstrap";
// import "./index.css";
// import { Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
// import { CloseIcon } from "../MUIComponents";

// const Logout = (props) => {

//     const LogoutClick = () => {       
// 		localStorage.clear('retailerid','token')
// 		console.log("logout",props.logout)	
// 		props.history.push({pathname:`/`})
// 	}
	
// 	const logout = () =>
// 	{	
		
// 		if(props.logout){
// 			props.history.push({pathname:`/`})
// 		}
// 	}
// 	const [open,setOpen] = useState(true)
// 	function handleClose(){
//     setOpen(false)
//   }
// 	return (
// 		<>
		

// 			{/* <Container className="form_container">
// 				<div className="form_div">
// 					<div id="signup-form">
// 						Are you sure you want to logout ? 
// 					</div>
// 					<Row>
// 						<Col>
// 						<Link to='/'>
// 							<button
// 							style={{
// 								height: "37px",
								
// 								backgroundColor: "#75c224",
// 								color: "white",
// 								fontSize: "16px",
// 								fontWeight: 600,
// 								border: "none",
// 							  }}
// 								id="submit-button"
// 								onClick={LogoutClick}
// 								variant="success">
// 								Yes
// 							</button>
// 							</Link>
// 							<Link to='/home'>
// 						<button
// 						style={{
// 							height: "37px",
					
// 							backgroundColor: "#75c224",
// 							color: "white",
// 							fontSize: "16px",
// 							fontWeight: 600,
// 							border: "none",
// 						  }}
// 								id="submit-button"
								
// 								variant="success">
// 								No
// 							</button>
// 							</Link>
// 						</Col>
						
// 					</Row>
// 				</div>
// 			</Container> */}
// 			 <Dialog
//                     open={open}
//                     onClose={handleClose}
//                     aria-labelledby="alert-dialog-title"
//                     aria-describedby="alert-dialog-description"
//                     PaperProps={{ sx: { borderRadius: "10px" } }}
//                   >
//                     <div
//                       onClick={handleClose}
//                       style={{
//                         position: "absolute",
//                         right: "8px",
//                         top: "8px",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <div
//                         style={{
//                           height: "27px",
//                           width: "27px",
//                           border: "2px solid #8B8989",
//                           borderRadius: "50%",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         }}
//                       >
//                         <CloseIcon style={{ color: "#8B8989" }} />
//                       </div>
//                     </div>

//                     <DialogTitle
//                       id="alert-dialog-title"
//                       style={{
//                         padding: "34px  64px 14px 64px",
//                         color: "#8B8989",
//                       }}
//                     >
//                       {"Are you sure you want to logout?"}
//                     </DialogTitle>
//                     <DialogActions style={{ padding: "14px 64px 24px 64px" }}>
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-evenly",
//                           width: "100%",
//                         }}
//                       >
//                         <Typography
//                           onClick={handleClose}
//                           style={{
//                             fontFamily: "Roboto",
//                             fontWeight: "900",
//                             cursor: "pointer",
//                           }}
//                         >
//                           Cancel
//                         </Typography>
//                         <div>|</div>
//                         <Typography
//                           onClick={LogoutClick}
//                           style={{
//                             fontFamily: "Roboto",
//                             fontWeight: "900",
//                             color: "#D40000",
//                             cursor: "pointer",
//                           }}
//                         >
//                           Logout
//                         </Typography>
//                       </div>
//                     </DialogActions>
//                   </Dialog>
// 			{props.logout==true?(
// 					logout()
// 			):(null)
// }
			
// 		</>
// 	);
// };
// export default Logout;


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, Button, Typography } from "@mui/material";
import { CloseIcon } from "../MUIComponents";

const Logout = (props) => {
  const [open, setOpen] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (props.logout) {
      history.push("/");
    }
  }, [props.logout, history]);

  const handleLogoutClick = () => {
    localStorage.removeItem("retailerid");
    localStorage.removeItem("token");
    history.push("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <div
          onClick={handleClose}
          style={{
            position: "absolute",
            right: "8px",
            top: "8px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              height: "27px",
              width: "27px",
              border: "2px solid #8B8989",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon style={{ color: "#8B8989" }} />
          </div>
        </div>

        <DialogTitle
          id="alert-dialog-title"
          style={{
            padding: "34px  64px 14px 64px",
            color: "#8B8989",
          }}
        >
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogActions style={{ padding: "14px 64px 24px 64px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Button onClick={handleClose} style={{ fontWeight: "900" }}>
              No
            </Button>
            <div>|</div>
            <Button
              onClick={handleLogoutClick}
              style={{ fontWeight: "900", color: "#D40000" }}
            >
              Yes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Logout;
