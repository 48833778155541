import React, { useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { NO_RESULT } from "../../utils/image";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import Label from "../Label";
import OrderListHead from "./OrderListHead";
import { IconEdit } from "@tabler/icons";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

const TABLE_HEAD = [
  { id: "Customer", label: "Customer", alignRight: false },
  { id: "Mobileno.", label: "Mobile Number", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "Action", label: "Action", alignRight: false },
];

export default function UsersList(props) {
  const [modalopen, setModalOpen] = React.useState(false);
  const [mopen, setMOpen] = React.useState(false);
  const [uses, setUses] = React.useState(null);

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  React.useEffect(() => {
    props.getUserSpecificCoupons();
  }, []);
  const [userId, setuserId] = React.useState(null);

  const [CList, setCList] = React.useState();

  const handleCheck = (id, event) => {
    console.log(event.target.checked);
    if (event.target.checked === true) setCList(id);
    else if (event.target.checked === false) {
      setCList();
    }
  };

  const handleClickListItem = (id) => {
    setModalOpen(true);
    setuserId(id);
    setUses(null);
    setCList([]);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleMClose = () => {
    if (uses) {
      props.AssignCouponTouser({ userId, CList, uses });
    }

    setMOpen(false);
  };
  const handleOk = () => {
    if (CList.length != 0) {
      setMOpen(true);
      handleClose();
      // props.AssignCouponTouser({userId,CList})
    } else handleClose();
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const getDetails = (id) => {
    // localStorage.setItem('orderid',id.toString());
    props.userDetails(id);
    props.history.push({
      pathname: "/home/Userdetails",
    });
  };
  /* eslint-disable*/
  const [open, setOpen] = React.useState(false);
  const [offset, setOffset] = useStateWithCallbackLazy(0);
  /* eslint-disable no-useless-escape */
  // const [users, setUsers] = React.useState([]);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().trim(),
    phoneNumber: Yup.string().matches(/^\d+$/, "only numbers").length(10),
    status: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setOffset(0);
      props.usersList(
        formik.values.email,
        formik.values.phoneNumber,
        formik.values.status,
        offset
      );
      setOpen(false);
    },
  });
  const Back = () => {
    setOffset(offset - 15);
  };

  const Next = () => {
    setOffset(offset + 15);
  };
  useEffect(
    () => {
      props.usersList(
        formik.values.email,
        formik.values.phoneNumber,
        formik.values.status,
        offset
      );
      // console.clear();
    },
    //eslint-disable
    [offset]
  );

  useEffect(() => {
    props.setUser(
      props.UsersList &&
        props.UsersList.searchUser &&
        props.UsersList.searchUser.items &&
        props.UsersList.searchUser.items.map((p) => p)
    );
  }, [props.UsersList]);

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          transform: "translateY(100px)",
          width: "90%",
          borderRadius: "30px",
          padding: "15px",
          boxShadow:
            "rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px",
        }}
        position="sticky"
      >
        <TableContainer
          style={{ width: "100%", overflowX: "auto", marginLeft: "0px" }}
        >
          <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h5
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                    color: "black",
                    textDecoration: "underline",
                  }}
                >
                  {"Search User"}
                </h5>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="email"
                  name="email"
                  InputProps={{
                    style: { borderColor: "#00C853", width: "100%" },
                  }}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  label="Search by Email"
                  variant="outlined"
                  placeholder="Email"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="phoneNumber"
                  InputProps={{
                    style: { borderColor: "#00C853", width: "100%" },
                  }}
                  label="Search by Mobile Number"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  variant="outlined"
                  placeholder="Mobile Number"
                  validation
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    name="status"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    onChange={formik.handleChange}
                  >
                    <MenuItem
                      value={"Active"}
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      Active Users
                    </MenuItem>
                    <br />
                    <MenuItem
                      value={"Blocked"}
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      Blocked Users
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <button
                  style={{
                    color: "rgb(255, 255, 255)",
                    backgroundImage:
                      "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                    boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                    height: "100%",
                    fontSize: "18px",
                    fontWeight: 600,
                    borderRadius: "5px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "transparent",
                    overflow: "hidden",
                    outline: "none",
                    fontFamily: "Varela Round, sans-serif",
                    textTransform: "none",
                    textAlign: "center",
                    width: "100%",
                  }}
                  type="submit"
                  color="success"
                  variant="contained"
                  fullWidth
                >
                  Search
                </button>
              </Grid>
            </Grid>
          </Form>
          {props.userslistloading ? (
            <CircularProgress disableShrink />
          ) : props.userslistsuccess ? (
            props.UsersList &&
            props.UsersList.searchUser &&
            props.UsersList.searchUser !== null && (
              <div>
                {props.Users.Users &&
                props.Users.Users.params &&
                props.Users.Users.params.length > 0 ? (
                  <Table>
                    <OrderListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {props.Users.Users.params.map((row, index) => (
                        <TableRow
                          hover
                          style={{ cursor: "pointer" }}
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">
                            <Stack
                              direction="column"
                              alignItems="left"
                              spacing={1}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {row.firstName && row.firstName}{" "}
                                {row.lastName && row.lastName}
                              </Typography>
                              {/* <br/> */}
                              <Typography variant="caption" noWrap>
                                {row.email && row.email && row.email.length > 14
                                  ? `${row.email.slice(0, 13)}...`
                                  : row.email}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {row.phoneNumber &&
                            row.phoneNumber &&
                            row.phoneNumber.length > 10
                              ? `${row.phoneNumber.slice(0, 10)}`
                              : row.phoneNumber}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                row.status && row.status === "Blocked"
                                  ? "error"
                                  : "success"
                              }
                            >
                              {row.status && row.status === "Blocked"
                                ? "Blocked"
                                : "Active"}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <IconEdit
                              role="button"
                              stroke={1}
                              style={{ width: 40 , height: "30px"}}
                              onClick={() => getDetails(row.id)}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "rgba(4, 59, 92, 1)",
                              fontWeight: 600,
                            }}
                            role="button"
                            onClick={() => handleClickListItem(row.id)}
                          >
                            Apply coupon
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div>
                    {isSmallScreen ? (
                      <div
                        style={{
                          width: "75vw",
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                          right: "7px",
                        }}
                      >
                        <h5>No Search Results</h5>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "75vw",
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ maxWidth: "810px", alignSelf: "center" }}
                          src={NO_RESULT}
                          alt="noresult"
                        ></img>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          ) : null}
        </TableContainer>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={modalopen}
        >
          <DialogTitle>Available coupons</DialogTitle>
          <DialogContent dividers>
            <FormControl component="fieldset">
              <FormGroup>
                {props.userCoupons &&
                  props.userCoupons.data &&
                  props.userCoupons.data.listUserSpecificCoupons &&
                  props.userCoupons.data.listUserSpecificCoupons.map(
                    (option) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={CList === option.id ? true : false}
                              onChange={(e) => handleCheck(option.id, e)}
                            />
                          }
                          label={`${option.couponCode} ( ${option.name} )`}
                        />
                      </>
                    )
                  )}
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={mopen}
          onClose={handleMClose}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              onKeyPress={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "."
                ) {
                  event.preventDefault();
                }
              }}
              id="name"
              label="Total uses per customer"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              fullWidth
              value={uses}
              onChange={(e) => {
                console.log(e.target.value);
                setUses(e.target.value);
              }}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleMClose}>Submit</Button>
          </DialogActions>
        </Dialog>

        <Row>
          <Col>
            {offset > 0 ? (
              <button
                style={{
                  padding: "10px",
                  color: "rgb(255, 255, 255)",
                  backgroundImage:
                    "linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                  boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                  height: "100%",
                  fontSize: "18px",
                  fontWeight: 600,
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "transparent",
                  overflow: "hidden",
                  outline: "none",
                  fontFamily: " Varela Round, sans-serif",
                  textTransform: "none",
                  textAign: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={Back}
              >
                Back
              </button>
            ) : null}
          </Col>
          <Col>
            {props.UsersList &&
            props.UsersList.searchUser &&
            props.UsersList.searchUser.hasNext === true ? (
              <button
                style={{
                  padding: "10px",
                  color: "rgb(255, 255, 255)",
                  backgroundImage:
                    "linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                  boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                  height: "100%",
                  fontSize: "18px",
                  fontWeight: 600,
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "transparent",
                  overflow: "hidden",
                  outline: "none",
                  fontFamily: " Varela Round, sans-serif",
                  textTransform: "none",
                  textAign: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={Next}
              >
                Next
              </button>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}
