import React, { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload, Modal, Button, message } from "antd";
import { Row, Col } from "react-bootstrap";
import "./index.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import CustomButton from "../CommonComponents/button";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const EditDisplayImageField = (props) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState();
  const [confirmUploadVisible, setConfirmUploadVisible] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
  

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleUploadConfirm = () => {
    console.log("Uploading file...");
    setFileList([]);
    setConfirmUploadVisible(false);
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const [show, setShow] = useState(true);
  function handleUpload() {
    console.log("file", fileList);
    const type = 2;

    const Imgdata = fileList[0]?.originFileObj;
    const size = fileList[0]?.size;
    const filename = fileList[0]?.name;
    console.log(Imgdata);
    // {fileList.map((each)=>(
    props?.uploadOrderImage({
      files: Imgdata,
      size: size,
      filename: filename,
      type: type,
    });
    console.log("hehe", props.orderImageUploaded);
    // console.log("HUHU",props.orderImageUplo)

    if (Imgdata === undefined) {
      message.error({
        content: "Please Upload the Image",
        className: "custom-class",
        style: {
          marginTop: "40vh",
          marginLeft: "40vh",
        },
      });
    } else {
      message.success({
        content: "Image Uploaded Succesfully",
        className: "custom-class",
        style: {
          marginTop: "40vh",
          marginLeft: "40vh",
        },
      });
    }
    if (props.newSignedUrl) {      
      const location = props?.newSignedUrl?.imageEditAndUpload?.Location;
    
      if (location) {
        localStorage.setItem("displayImage", location);
      }
    }
  }

  return (
    <>
      <Row>
        <Col xs={6}>
          <ImgCrop
            modalTitle="product"
            modalWidth={500}
            modalHeight={500}
            minZoom={0.1}
            aspect={1 / 1}
            rotate={true}
            objectFit="vertical-cover"
            grid
          >
            <Upload
              maxCount={1}
              customRequest={dummyRequest}
              accept="image/*"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onRemove={handleRemove}
              onChange={onChange}
            >
              {fileList.length < 1 && "Click here to choose the image"}
            </Upload>
          </ImgCrop>
        </Col>
        <Col xs={12}>{/* <Cards/> */}</Col>
      </Row>
      {fileList.length !== 0 &&
      props.masterUrl === null &&
      props.proImage === null ? (
        <Button
          style={{
            marginTop: "10px",
            backgroundColor: "rgb(83,170,73)",
            color: "white",
            border: "none",
          }}
          onClick={() => setConfirmUploadVisible(true)}
        >
          Confirm Upload
        </Button>
      ) : null}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <img alt="example" style={{ width: "50%" }} src={previewImage} />
      </Modal>
      <Modal
        visible={confirmUploadVisible}
        title="Confirm Upload"
        onCancel={() => setConfirmUploadVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setConfirmUploadVisible(false)}>
            Cancel
          </Button>,
          <Button key="upload" type="primary" onClick={handleUploadConfirm}>
            Upload
          </Button>,
        ]}
      >
        Are you sure you want to upload this image?
      </Modal>
      {confirmUploadVisible && (
        <CustomButton
          style={{
            marginTop: "10px",
            backgroundColor: "rgb(83,170,73)",
            color: "white",
            border: "none",
          }}
          onClick={handleUploadConfirm}
        >
          Upload
        </CustomButton>
      )}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <CustomButton onClick={handleUpload}>Upload</CustomButton>
      </div>
    </>
  );
};

export default EditDisplayImageField;
