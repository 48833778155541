// import React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
// import Details from './Details';
// import StatusStepper from './StatusStepper';

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   backgroundColor: '#ebedef'
// }));

// const Orderdetails = (props) => {

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} md={8}>
//           <Item>
//             {
//             props?.OrderDetailsSuccess&&
//             props?.OrderDetailsSuccess===true&&props?.OrderDetails &&
//             props?.OrderDetails?.orderdetails ?
//             <Details {...props} />
//             :null}
//           </Item>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           {props?.OrderDetails &&
//             props?.OrderDetails?.orderdetails &&
//             props?.OrderDetails?.orderdetails?.paymentActive &&
//             props?.OrderDetails?.orderdetails?.paymentActive === true &&
//             <Item>
//               <Paper
//                 square
//                 elevation={0}>
//                 <b> {`Payment Method : ${
//                   props?.OrderDetails?.orderdetails?.paymentStatus === 2 ?
//                   'Online' :
//                     props?.OrderDetails?.orderdetails?.paymentStatus === 1 ?
//                     'COD' :
//                     props?.OrderDetails?.orderdetails?.paymentStatus === 0?
//                     'Not Paid'
//                     :'Not Paid'
//                   }`}
//                 </b>
//               </Paper>
//             </Item>}
//           <Item>
//             {props?.OrderDetails &&
//               props?.OrderDetails?.orderdetails &&
//             <StatusStepper
//              status={props?.OrderDetails &&
//               props?.OrderDetails?.orderdetails &&
//               props?.OrderDetails?.orderdetails?.stats &&
//               props?.OrderDetails?.orderdetails?.stats} orderId={props.orderId}
//               orderData={props?.OrderDetails &&
//                          props?.OrderDetails?.orderdetails} {...props} />
//               }
//           </Item>
//         </Grid>
//       </Grid>
//     </Box>
//   )
// }

// export default Orderdetails
import React, { useEffect, useRef, useState } from "react";
import {
  AdapterDateFns,
  AdapterDayjs,
  Alert,
  Box,
  Breadcrumbs,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LocalizationProvider,
  Paper,
  Stack,
  TextField,
  TimePicker,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import { Link } from "../MUIComponents/index";
import Direction from "../Utilities/Icon/Direction.svg";
import Pointer from "../Utilities/Icon/Pointer.svg";
import Phone from "../Utilities/Icon/Phone.svg";
import Triangle from "../Utilities/Icon/Triangle.svg";
import Lines from "../Utilities/Icon/Lines.jpg";
import Line2 from "../Utilities/Icon/Line2.svg";
import "./NewOrder.css";
import CustomButton from "../CommonComponents/button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import { useNavigate } from "react-router-dom";
import {
  contentFontSize_16,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  ButtonFontSize_16,
  ButtonFontSize_18,
  contentFontsize_36,
  contentFontsize_24,
  contentFontsize_28,
  contentFontsize_17,
} from "../Typography";
import { contentFontSize_18 } from "../Typography";
import CustomCard from "../CommonComponents/card";
import CustomDialog from "../CommonComponents/dialog";
import { Flex, message } from "antd";
import Loaders from "./Loader/index";
import { useFormik } from "formik";
import { validationSchema } from "./Validation";
import { each } from "lodash";
import ActiveOrder from "./ActiveOrder";
import { id } from "date-fns/locale";
import ReadyOrder from "./ReadyOrder";
import OutForDelivery from "./OutForDelivery";
import OrderHistory from "./OrderHistory";
import OrderRejected from "./OrderRejected";
import CheckIcon from "@mui/icons-material/Check";
import LineInReadyForDel from "../Utilities/Icon/LineInReadyForDel.svg";
// import { useFormik } from "formik";
import * as Yup from "yup";
import OrderCancel from "./OrderCancel";
import { Button } from "react-bootstrap";
function NewOrder(props) {
  console.log("p", props);
  const orderid = localStorage.getItem("orderID");
  useEffect(() => {
    props.getOrderDetails(orderid);
    console.log("one", props.getOrderDetails(orderid));
  }, []);
  const newOrderDetails =
    props?.OrderDetails && props?.OrderDetails?.orderdetails;
  console.log("newOrderDetails", newOrderDetails);
  // const orderid = newOrderDetails?.id;
  // console.log("id", orderid);

  const newO =
    ("props",
    props &&
      props.ChangetoActiv &&
      props.ChangetoActive.changeOrderStatusToShopping);
  console.log("eeeee", newO);

  const [activeButton, setActiveButton] = useState(false);
  const [active, setActive] = useState("");
  const [state, setState] = useState([]);

  const [loading, setLoading] = useState(false);
  const { ChangetoActiveStatusSuccess, ChangeToActiveLoading } = props;

  const [preparationTime, setpreparationTime] = useState(null);
  console.log("pt", preparationTime);
  const [showOrderPlaced, setShowOrderPlaced] = useState(false);
  const [showerror, setShowError] = useState(false);
  function handleButtonAccept() {
    if (preparationTime === null) {
      setShowError(true);
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);

        props.ChangetoActiveStatus(orderid, preparationTime);
        setShowOrderPlaced(true);
      }, 2000);
    }
  }
  useEffect(() => {
    if (showerror) {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showerror]);

  console.log("order", props.orderData);

  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [error, setError] = useState(null);

  function handleButtonOne() {
    setActiveButton("15 min");
    setpreparationTime("15");
    setPick(false);
  }
  function handleButtonTwo() {
    setActiveButton("30 min");
    setpreparationTime("30");
    setPick(false);
  }
  function handleButtonThree() {
    setActiveButton("45 min");
    setpreparationTime("45");
    setPick(false);
  }
  function handleButtonFour() {
    setActiveButton("55 min");
    setpreparationTime("55");
    setPick(false);
  }
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [change, setChange] = useState(false);

  const validationSchema = Yup.object({
    hour: Yup.number()
      .typeError("The hour should be a number")
      .min(0, "Hour must be at least 0")
      .max(23, "Hour must be at most 23")
      .required("Hour is required"),
    minute: Yup.number()
      .typeError("The minute should be a number")
      .min(0, "Minute must be at least 0")
      .max(59, "Minute must be at most 59")
      .required("Minute is required"),
  });

  const [show, setShow] = useState(false);

  const [pick, setPick] = useState(false);

  const formik = useFormik({
    initialValues: {
      hour: "",
      minute: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const totals =
        parseInt(values.hour, 10) * 60 + parseInt(values.minute, 10);
      setpreparationTime(totals);
      if (isNaN(totals)) {
        message.error({
          content: "Please choose the preparation time",
          className: "custom-class",
          style: {
            marginTop: "40vh",
            marginLeft: "40vh",
          },
        });
      } else {
        setShow(false);
        setPick(`${values.hour}hr ${values.minute}min`);
        formik.resetForm();
      }
    },
  });

  const handleButtonFive = () => {
    if (activeButton !== "Pick a time") {
      setPick(false); // Reset the pick state when a different button is selected
    }
    setActiveButton("Pick a time");
    setShow(true);
  };
  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  function handleClosed() {
    setShow(false);
    setOpen(false);
    formik.resetForm();
  }

  const [open, setOpen] = useState(false);
  const [commentsSeller, setCommentsSeller] = useState(null);
  // function handleReject() {
  //   setOpen(true);
  // }
  // const handleChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setReason(selectedValue);
  //   setCommentsSeller(selectedValue);
  //   console.log("Selected value:", selectedValue);
  // };
  const [give, setGive] = useState(false);
  function handleReject() {
    // props.rejectedStatusHandler(orderid,value,newOrderDetails.lastStatus)
    setOpen(true);
  }
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setReason(selectedValue);
    setCommentsSeller(selectedValue);
    console.log("Selected value:", selectedValue);
    if (selectedValue === "Other") {
      setGive(true);
    }
  };
  function handleClose() {
    setActive("cancel");
    setOpen(false);
  }
  const status = "Order-Rejected";
  const situation = 0;
  console.log("stats", status);
  console.log("t", newOrderDetails?.id);
  //  const reject ={orderid:orderid,commentSeller:value,stats:stats}
  const [reason, setReason] = useState("");
  const [rejected, setReject] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [success, setSuccess] = useState(false);
  console.log("huhu", props.rejectedDetails?.updateOrderStatus);

  function handleApply() {
    setOpen(false);
    setActive("apply");
    // if (reason === "") {
    //   setShowReason(true);
    //   console.log("show",showReason)
    // }
    if (reason === "") {
      message.error({
        content: "Please provide the reason for rejecting the order",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "60vh",
        },
      });
    } else {
      props.rejectedStatusHandler({
        commentsSeller: commentsSeller,
        orderid: orderid,
        stats: { status: status },
      });
      setSuccess(true);
    }

    // if (
    //   props.rejectedDetails?.updateOrderStatus.lastStatus === "Order-Rejected"
    // ) {
    //   setReject(true);
    //   //setOpen(false);
    //   setSuccess(true);
    // }

    // console.log("rejected", rejected);
    // console.log("r", props.rejected);

    console.log("id", orderid);
  }

  // function handleApply() {
  //   setOpen(false);
  //   setActive("apply");
  //   if (reason === "") {
  //     setShowReason(true);
  //   }
  //   props.rejectedStatusHandler({
  //     commentsSeller: commentsSeller,
  //     orderid: orderid,
  //     stats: { status: status },
  //   });
  //   setSuccess(true);
  //   // if (
  //   //   props.rejectedDetails?.updateOrderStatus.lastStatus === "Order-Rejected"
  //   // ) {
  //   //   setReject(true);
  //   //   //setOpen(false);
  //   //   setSuccess(true);
  //   // }

  //   // console.log("rejected", rejected);
  //   // console.log("r", props.rejected);

  //   console.log("id", orderid);
  // }
  // useEffect(() => {
  //   props.rejectedStatusHandler({
  //     commentsSeller: commentsSeller,
  //     orderid: orderid,
  //     stats: { status: status },
  //   });
  // }, []);
  // useEffect(() => {
  //   if (showReason) {
  //     const timer = setTimeout(() => {
  //       setShowReason(false);
  //     }, 1500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [showReason]);
  useEffect(() => {
    if (rejected) {
      setSuccess(true);
    }
  }, []);
  // useEffect(() => {
  //   if (success) {
  //     const timer = setTimeout(() => {
  //       setSuccess(false);
  //     }, 1500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [success]);
  const storeid = localStorage.getItem("storeid");
  const deliveryDate = newOrderDetails?.deliveryDate;
  const deliveryTime = newOrderDetails?.deliveryTime;
  const orderNumber = newOrderDetails?.orderNumber;
  const shopAssistantId = newOrderDetails?.shopAssistantId;
  {
    props.rejected &&
      props.scheduleAlert({
        storeid: storeid,
        orderid: orderid,
        status: status,
        deliveryDate: deliveryDate,
        deliveryTime: deliveryTime,
        orderNumber: orderNumber,
        shopAssistantId: shopAssistantId,
      });
  }
  const instructions = ["add more Salt"];
  let totalPrice = 0;

  if (newOrderDetails && newOrderDetails.products) {
    newOrderDetails.products.forEach((item) => {
      totalPrice += (item?.quantity || 0) * (item?.price || 0);
    });
  }

  console.log("totalPrice:", totalPrice);
  console.log("pr", props.ChangeToActiveLoading);

  function handleClickOne() {
    // nav("/home/MyOrders");
  }
  useEffect(() => {
    const orderid = localStorage.getItem("orderID");
    props.getSearchDetails(orderid);
  }, []);
  useEffect(() => {
    const orderid = localStorage.getItem("orderID");
    props.getOrderDetails(orderid);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const [showScanner, setShowScanner] = useState(false);
  const [showCollected, setShowCollected] = useState(false);

  const getTimeString = (time) => {
    return time
      ? time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : "";
  };
  console.log("hello", props?.OrderDetails?.orderdetails);
  return (
    <div style={{ marginLeft: "30px" }}>
      {!loading &&
      !showOrderPlaced &&
      !success &&
      props?.OrderDetails?.orderdetails?.lastStatus === "Order-Placed" ? (
        <>
          <div style={{ display: "flex", gap: "50px" }}>
            <div style={{ width: "55%" }}>
              <div style={{ marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img
                          src={Direction}
                          style={{ width: "20px", height: "20px" }}
                          alt="Direction Icon"
                        />

                        <div>
                          <p className="nameStyle1" style={{ color: "black" }}>
                            {" "}
                            {newOrderDetails && newOrderDetails.customerName}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          {newOrderDetails && newOrderDetails?.deliveryAddress},
                          {newOrderDetails && newOrderDetails?.deliveryDate},
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {newOrderDetails && newOrderDetails.deliverytime}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        height: "100%",
                        marginTop: "18px",
                      }}
                    >
                      <img
                        src={Phone}
                        style={{ width: "20px", height: "20px" }}
                        alt="Phone icon"
                      />
                      <span>
                        <p className="numberStyle1" style={{ color: "black" }}>
                          +91 {newOrderDetails?.userid?.phoneNumber}
                        </p>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="neworderBox">
                <span>
                  <p
                    fontSize={contentFontsize_17}
                    className="textStyleNew"
                    style={{ color: "black" }}
                  >
                    #{newOrderDetails && newOrderDetails.orderNumber} -
                    Scheduled on{" "}
                    {newOrderDetails && newOrderDetails.deliveryDate},{" "}
                    {newOrderDetails && newOrderDetails.deliveryTime}
                  </p>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "20px",
                  padding: smmatch ? "10px 10px" : "0px 50px 0px 0px",
                }}
              >
                {newOrderDetails &&
                  newOrderDetails?.products?.map((item) => (
                    <>
                      <div style={{ display: "flex" }}>
                        {console.log("item vannu", item)}
                        <div style={{ flex: "1" }}>
                          <div style={{ display: "flex", gap: "60px" }}>
                            <div className="productDetailsNew">
                              <img
                                style={{
                                  width: matchessm ? "15px" : "20px",
                                  height: matchessm ? "15px" : "20px",
                                  marginLeft: "10px",
                                }}
                                src={Pointer}
                                alt={item?.name}
                              />
                            </div>

                            <div>
                              <p
                                fontSize={contentFontsize_17}
                                className="productDetailsNew"
                                style={{
                                  marginBottom: "0px",
                                  textAlign: "left",
                                  color: "black",
                                }}
                              >
                                {item.quantity} X {item.productid.productname}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div style={{ flex: "1" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1px",
                              }}
                            >
                              {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                              <p
                                className="productDetailsNew"
                                fontSize={contentFontsize_17}
                                style={{ color: "black" }}
                              >
                                ₹
                                {(item?.quantity * item?.productPrice)?.toFixed(
                                  2
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div>
                <img src={Line2} className="image_container2" />
              </div>

              <div
                style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Item Total :
                  </p>
                  {/* </Grid> */}
                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
                  <p className="productDetails1" fontSize={contentFontSize_16}>
                    ₹{" "}
                    {newOrderDetails?.shoppingTotalPrice != null
                      ? newOrderDetails.shoppingTotalPrice
                      : newOrderDetails?.totalPrice != null
                      ? newOrderDetails.totalPrice
                      : "0.00"}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Partner Delivery Fee:
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                  ₹{" "}{(newOrderDetails.deliveryCharge ?? 0).toFixed(2)}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    GST :
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "}
                    {props?.newOrderDetails?.shoppingGST != null
                      ? parseFloat(
                          props.newOrderDetails.shoppingGST
                        ).toFixed(2)
                      : props?.newOrderDetails?.GST != null
                      ? parseFloat(props.newOrderDetails.GST).toFixed(2)
                      : "0.00"}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Packing & Handling Charges :
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "}
                    {newOrderDetails?.shoppingPackingCharge != null &&
                    newOrderDetails?.handlingCharge != null
                      ? newOrderDetails.shoppingPackingCharge +
                        newOrderDetails.handlingCharge
                      : newOrderDetails?.packingCharge != null &&
                        newOrderDetails?.handlingCharge != null
                      ? newOrderDetails.packingCharge +
                        newOrderDetails.handlingCharge
                      : "0.00"}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Tip For Delivery Partner:
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "}
                    {newOrderDetails?.deliveryTip != null
                      ? newOrderDetails.deliveryTip
                      : "0.00"}
                  </p>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Platform Fee :
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "}
                    {newOrderDetails?.shoppingPlatformFee != null
                      ? parseFloat(
                          Number(newOrderDetails.shoppingPlatformFee)
                        ).toFixed(2)
                      : newOrderDetails?.platformFee != null
                      ? parseFloat(Number(newOrderDetails.platformFee)).toFixed(
                          2
                        )
                      : "0.00"}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Total :
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "}
                    {newOrderDetails?.shoppingTotal != null
                      ? parseFloat(newOrderDetails.shoppingTotal).toFixed(2)
                      : newOrderDetails?.total != null
                      ? parseFloat(newOrderDetails.total).toFixed(2)
                      : "0.00"}
                  </p>
                </div>

                {(newOrderDetails.discountPrice &&
                  newOrderDetails.discountPrice !== null) ||
                newOrderDetails?.couponID?.type === 4 ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      style={{ color: "rgba(45, 147, 251, 1)" }}
                      className="productDetails1"
                    >
                      Coupon - ({newOrderDetails?.couponID?.couponCode})
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                    <p
                      className="productDetails1"
                      style={{ color: "rgba(45, 147, 251, 1)" }}
                      fontSize={contentFontSize_16}
                    >
                      -₹{" "}
                      {newOrderDetails?.shoppingDiscountPrice !== null &&
                      newOrderDetails.shoppingDiscountPrice !== undefined
                        ? parseFloat(
                            Number(newOrderDetails.shoppingDiscountPrice)
                          ).toFixed(2)
                        : newOrderDetails.discountPrice !== null &&
                          newOrderDetails.discountPrice !== undefined
                        ? parseFloat(
                            Number(newOrderDetails.discountPrice)
                          ).toFixed(2)
                        : "0.00"}{" "}
                    </p>
                  </div>
                ) : null}
              </div>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <img
                    style={{ width: "100%" }}
                    src={LineInReadyForDel}
                    alt="LineInReadyForDel"
                  />
                </Grid>
              </Grid>
              <div
                style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    fontSize={contentFontSize_20_xs_18}
                    style={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      color: "black",
                      marginTop: "15px",
                    }}
                  >
                    Total Payable :
                  </p>
                  {/* </Grid>
  
                      <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <p
                    style={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      color: "black",
                      marginTop: "15px",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    ₹{" "}
                    {newOrderDetails?.shoppingTotalPayable != null
                      ? parseFloat(
                          Number(newOrderDetails.shoppingTotalPayable)
                        ).toFixed(2)
                      : newOrderDetails?.totalPayable != null
                      ? parseFloat(
                          Number(newOrderDetails.totalPayable)
                        ).toFixed(2)
                      : "0.00"}
                  </p>
                </div>
              </div>

              <div>
                <img src={Line2} className="image_container2" />
              </div>
              <div>
                {showerror && (
                  <Stack>
                    <Alert
                      variant="outlined"
                      severity="warning"
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      Please Provide Preperation Time
                    </Alert>
                  </Stack>
                )}
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "700",
                    marginTop: "30px",
                    marginLeft: "0px",
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  Delivery Instructions
                  <br />
                  <span>
                    <p
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        color: "#8B8989",
                        marginTop: "10px",
                        textAlign: "left",
                      }}
                    >
                      {newOrderDetails?.deliveryInstructions === null ||
                      newOrderDetails.specialInstructions === ""
                        ? "no instructions are provided"
                        : newOrderDetails?.deliveryInstructions}
                    </p>
                  </span>
                </p>
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "700",
                    marginTop: "10px",
                    marginLeft: "0px",
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  Cooking Instructions
                  <br />
                  <span>
                    <p
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        color: "#8B8989",
                        marginTop: "10px",
                        textAlign: "left",
                      }}
                    >
                      {newOrderDetails?.specialInstructions === null ||
                      newOrderDetails.specialInstructions === ""
                        ? "no instructions are provided"
                        : newOrderDetails?.specialInstructions}
                    </p>
                  </span>
                </p>
              </div>
              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    marginLeft: "0px",
                    color: "black",
                    textAlign: "left",
                  }}
                  fontSize={contentFontSize_20}
                >
                  Choose Preparation time
                </p>
              </div>
              <div className="gridStyle3">
                <div>
                  {" "}
                  <CustomButton
                    color={activeButton === "15 min" ? "white" : "black"}
                    variant={
                      activeButton === "15 min" ? "contained" : "outlined"
                    }
                    background={
                      activeButton === "15 min" ? "#659B1B" : "transparent"
                    }
                    borderColor="#659B1B"
                    onClick={handleButtonOne}
                    width="90px"
                    borderRadius="10px"
                    height="45px"
                    matchPadding={true}
                    padding="5px 10px 5px 10px"
                  >
                    <p className="pButton">15 min</p>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    color={activeButton === "30 min" ? "white" : "black"}
                    variant={
                      activeButton === "30 min" ? "contained" : "outlined"
                    }
                    textColor="white"
                    background={
                      activeButton === "30 min" ? "#659B1B" : "transparent"
                    }
                    borderColor="#659B1B"
                    onClick={handleButtonTwo}
                    width="90px"
                    borderRadius="10px"
                    height="45px"
                    matchPadding={true}
                    padding="5px 10px 5px 10px"
                  >
                    <p className="pButton">30 min</p>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    color={activeButton === "45 min" ? "white" : "black"}
                    variant={
                      activeButton === "45 min" ? "contained" : "outlined"
                    }
                    textColor="white"
                    background={
                      activeButton === "45 min" ? "#659B1B" : "transparent"
                    }
                    borderColor="#659B1B"
                    onClick={handleButtonThree}
                    width="90px"
                    borderRadius="10px"
                    height="45px"
                    matchPadding={true}
                    padding="5px 10px 5px 10px"
                  >
                    <p className="pButton" fontSize={ButtonFontSize_16}>
                      45 min
                    </p>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    color={activeButton === "55 min" ? "white" : "black"}
                    variant={
                      activeButton === "55 min" ? "contained" : "outlined"
                    }
                    textColor="white"
                    background={
                      activeButton === "55 min" ? "#659B1B" : "transparent"
                    }
                    borderColor="#659B1B"
                    onClick={handleButtonFour}
                    width="90px"
                    borderRadius="10px"
                    height="45px"
                    matchPadding={true}
                    padding="5px 10px 5px 10px"
                  >
                    <p className="pButton">55 min</p>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    color={activeButton === "Pick a time" ? "white" : "black"}
                    width="130px"
                    variant={
                      activeButton === "Pick a time" ? "contained" : "outlined"
                    }
                    textColor="white"
                    background={
                      activeButton === "Pick a time" ? "#659B1B" : "transparent"
                    }
                    borderColor="#659B1B"
                    onClick={handleButtonFive}
                    borderRadius="10px"
                    height="100%"
                    matchPadding={true}
                    padding="5px 6px 5px 6px"
                  >
                    <p className="pButton">
                      {/* {submit
                          ? `${
                              hour !== undefined && minute !== undefined
                                ? `${hour}hrs ${minute} minutes`
                                : "Pick a time"
                            }`
                          : "Pick a time"} */}
                      {pick || "Pick a time"}
                    </p>
                  </CustomButton>
                  {show && (
                    <CustomDialog
                      open={show}
                      showCloseButton={true}
                      onClose={handleClosed}
                      top="10px"
                      right="10px"
                    >
                      <DialogTitle
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Enter Time:
                      </DialogTitle>
                      <DialogContent>
                        <Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <div>
                              <TextField
                                id="hour"
                                placeholder="Hour"
                                variant="standard"
                                name="hour"
                                value={formik.values.hour}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.hour &&
                                  Boolean(formik.errors.hour)
                                }
                                helperText={
                                  formik.touched.hour && formik.errors.hour
                                }
                              />
                            </div>
                            <div style={{ marginTop: "20px" }}>:</div>
                            <div>
                              <TextField
                                id="minute"
                                placeholder="Minute"
                                variant="standard"
                                name="minute"
                                value={formik.values.minute}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.minute &&
                                  Boolean(formik.errors.minute)
                                }
                                helperText={
                                  formik.touched.minute && formik.errors.minute
                                }
                              />
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <CustomButton
                          width="200px"
                          height="40px"
                          borderRadius="10px"
                          onClick={formik.handleSubmit}
                        >
                          <p
                            style={{
                              textTransform: "capitalize",
                              marginBottom: "0px",
                            }}
                          >
                            Submit
                          </p>
                        </CustomButton>
                      </DialogActions>
                    </CustomDialog>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "0px",
                  justifyContent: "center",
                  height: "13vh",
                }}
              >
                <div className="helpTag">
                  <p
                    fontSize={contentFontSize_18}
                    style={{
                      color: "#85BE49",
                      textDecoration: "none",
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      marginLeft: "86px",
                    }}
                  >
                    This order will be delivered by the store.
                    <span style={{ textDecoration: "underline" }}>
                      {" "}
                      Need help ?
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "150px" }}>
              {newOrderDetails.stats.map((each) => (
                <div
                  style={{ display: "flex", gap: "10px", overflow: "hidden" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "80px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            background: "#85BE49",
                            height: "10px",
                            width: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <div
                          style={{
                            background: "#85BE49",
                            height: "10px",
                            width: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </div>
                      <div className="connecting-lineNew"></div>
                      {/* </div> */}
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                        lineHeight: "1",
                        gap: "30px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <div
                        fontSize={contentFontSize_18}
                        style={{
                          fontWeight: "800",
                          fontFamily: "Roboto",
                          color: "#000000",
                        }}
                      >
                        {each.status}
                        <br />
                        {each.status === "Order-Placed" && (
                          <p
                            style={{
                              color: "grey",
                              fontSize: "14px",
                              marginTop: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            {each.created},{each.createdTime}
                          </p>
                        )}
                      </div>

                      <div
                        fontSize={contentFontSize_18}
                        style={{
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        Scheduled for {newOrderDetails.deliveryDate},
                        {newOrderDetails.deliveryTime}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="buttonContainer">
            <CustomButton
              width="22%"
              height="50px"
              onClick={handleButtonAccept}
              borderRadius="20px"
            >
              <p
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  textAlign: "center",
                  marginBottom: "0px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                {" "}
                Accept
              </p>
            </CustomButton>
            <CustomButton
              width="22%"
              height="50px"
              borderRadius="20px"
              onClick={handleReject}
              color="black"
              background="white"
              borderColor="rgba(114, 176, 29, 1)"
            >
              {" "}
              <p
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  textAlign: "center",
                  marginBottom: "0px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
                fontSize={ButtonFontSize_18}
              >
                Reject
              </p>
            </CustomButton>
            {open && (
              <Dialog
                width="100%"
                height="600px"
                borderRadius="20px"
                open={open}
                onClose={handleClosed}
                showCloseButton={true}
                right="20px"
                top="20px"
                bottom="20px"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "600px",
                      // Set your width here
                      padding: "10px 10px 30px 10px",
                      borderRadius: "20px",
                    },
                  },
                }}
              >
                <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <Typography
                      fontSize={contentFontsize_24}
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "700",
                        color: "black",
                      }}
                    >
                      Are you sure
                    </Typography>
                    <Typography
                      fontSize={contentFontsize_24}
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "700",
                        color: "black",
                      }}
                    >
                      you want to reject this order?
                    </Typography>
                  </div>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        height: "27px",
                        width: "27px",
                        border: "2px solid #8B8989",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CloseIcon style={{ color: "#8B8989" }} />
                    </div>
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div>
                    <Typography
                      fontSize={contentFontSize_20}
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Please specify the reason for rejecting this order:
                    </Typography>
                  </div>
                  <div>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={commentsSeller}
                        onChange={handleChange}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Product Unavailable"
                          control={<Radio />}
                          label="Product Unavailable"
                        />
                        <FormControlLabel
                          value="Longer Preparation Time"
                          control={<Radio />}
                          label="Longer Preparation Time"
                        />
                        <FormControlLabel
                          value="Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {give && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography
                            fontSize={contentFontSize_20}
                            sx={{
                              fontFamily: " Roboto",
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            Please specify the reason for rejecting this order:
                          </Typography>
                        </div>
                        <div>
                          <Typography sx={{ marginTop: "3px" }}>
                            0/300
                          </Typography>
                        </div>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <Box
                          component="form"
                          sx={{
                            width: "650px",
                            maxWidth: "100%",
                            // corrected syntax
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            onChange={handleChange}
                            multiline={true}
                            rows="2"
                          />
                        </Box>
                      </div>
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "30px",
                      marginRight: "35px",
                    }}
                  >
                    <CustomButton
                      onClick={handleClose}
                      width="230px"
                      height="43px"
                      // variant={active === "cancel" ? "contained" : "outlined"}
                      // background={active === "cancel" ? "#659B1B" : "transparent"}
                      // color={active === "cancel" ? "white" : "#659B1B"}
                      borderRadius="25px"
                      borderColor="#659B1B"
                      color="black"
                      background="white"
                      variant="outlined"
                    >
                      <p
                        style={{
                          textTransform: "capitalize",
                          marginBottom: "0px",
                        }}
                      >
                        Cancel
                      </p>
                    </CustomButton>
                    <CustomButton
                      // onClick={() => handleChange(selectedItem)}
                      width="230px"
                      height="43px"
                      // variant={active === "change" ? "contained" : "outlined"}
                      // background={active === "change" ? "#659B1B" : "transparent"}
                      // color={active === "change" ? "white" : "#659B1B"}
                      borderRadius="25px"
                      borderColor="#659B1B"
                      onClick={handleApply}
                      color="white"
                      background="#659B1B"
                      variant="contained"
                    >
                      <p
                        style={{
                          textTransform: "capitalize",
                          marginBottom: "0px",
                        }}
                      >
                        Reject
                      </p>
                    </CustomButton>
                  </div>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </>
      ) : props?.OrderDetails?.orderdetails.lastStatus ===
        "Shopping-In-Progress" ? (
        <ActiveOrder {...props} />
      ) : props?.OrderDetails?.orderdetails.lastStatus === "Order-Ready" ? (
        <ReadyOrder {...props} />
      ) : props?.OrderDetails?.orderdetails.lastStatus ===
        "Out-For-Delivery" ? (
        <OutForDelivery {...props} />
      ) : props?.OrderDetails?.orderdetails.lastStatus === "Completed" ? (
        <OrderHistory {...props} />
      ) : props?.OrderDetails?.orderdetails.lastStatus === "Order-Rejected" ? (
        <OrderRejected {...props} />
      ) : props?.OrderDetails?.orderdetails.lastStatus === "Order-Cancelled" ? (
        <OrderCancel {...props} />
      ) : null}
      {loading && preparationTime && <Loaders />}
      {showOrderPlaced && <ActiveOrder {...props} />}
      {success && <OrderRejected {...props} />}
    </div>
  );
}

export default NewOrder;
