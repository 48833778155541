import React from "react";
import NavBar from "../../Components/NavBar";
import { Route } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import StoreSearch from "../../Components/StoreSearch";
import OrderDetails from "../../Components/OrderDetails";
import OrderSearch from "../../Components/OrderSearch";
import ActiveOrders from "../../Components/ActiveOrders";
import UsersList from "../../Components/UsersList";
import UserDetails from "../../Components/UserDetails";
import Complaints from "../../Components/Complaints";
import Details from "../../Components/Complaints/Details";
import Dashboard from "../../Components/Dashboard";
import Productstable from "../../Components/NewProducts/Productstable";
import ProductDetails from "../../Components/NewProducts/ProductDetails";
import StoreStepper from "../../Components/StoreStepper";
import Heirarchy from "../../Components/Heirarchy";
import Add from "../../Components/AddProducts/add";
import UpdateProduct from "../../Components/UpdateProduct";
import Coupons from "../../Components/Coupons";
import PercentageCoupon from "../../Components/Coupons/PercentageCoupon";
import FlatDiscount from "../../Components/Coupons/FlatDiscount";
import BuyXgetYCoupon from "../../Components/Coupons/BuyXgetYCoupon";
import FreeDEliveryCoupon from "../../Components/Coupons/FreeDeliveryCoupon";
import ProductDetailsCard from "../../Components/UpdateProduct/ProductDetailsCard";
import ExcelUpload from "../../Components/ExcelUpload";
import StoreUpload from "../../Components/ExcelUpload/StoreUpload";
import Payment from "../../Components/Payment/index";
import io from "socket.io-client";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import OrderListHead from "../../Components/SearchOrderResults/OrderListHead";
import Label from "../../Components/Label";
import order from "../../assets/order.mp3";
import CloseIcon from "@mui/icons-material/Close";
import GoogleMapOrder from "../../Components/OrderDetailsAssets/GoogleMapOrder";
import PaymentTwo from "../../Components/Payment/PaymentTwo";
import NonProcessed from "../../Components/Payment/Completed";
import View from "../../Components/Payment/View";
import PendingView from "../../Components/Payment/PendingView";
import Completed from "../../Components/Payment/Completed";
import Reports from "../../Components/Reports";
import Settings from "../../Components/Settings";
//import { Payments } from "@mui/icons-material";
//import FirstPage from "../../Components/Payment/FirstPage";

const TABLE_HEAD = [
  { id: "Customername", label: "Customer Name", alignRight: false },
  { id: "orderno", label: "Order No.", alignRight: false },
  { id: "date", label: "Delivery Date", alignRight: false },
  { id: "time", label: "Delivery Time", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

const SignIn = (props) => {
  const [setState] = React.useState({ mainList: 0, SubList: 0 });
  // eslint-disable-next-line
  const [state, setstate] = React.useState({
    orderstatus: "",
    deliveryDateFrom: "",
    deliveryDateTo: "",
    dateFrom: "",
    dateTo: "",
    orderNumber: "",
    customerMobile: "",
    first: 10,
    offset: 0,
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const selectedList = (item, index) => {
    props.history.push(
      `/home/${item.title}/${item.subMenus[index].replace(/ /g, "")}`
    );
    setState({
      mainList: item.id,
      SubList: index,
    });
  };
  const [socket, setSocket] = React.useState(null);
  // eslint-disable-next-line
  const [messages, setMessages] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [liveorderdata, setLiveorderdata] = React.useState([]);

  React.useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  React.useEffect(
    () => {
      const messageListener = (message) => {
        let audio = new Audio(order);
        audio.play();
        if (message !== null) {
          setOpen(true);
          props.setLiveOrder(message);
        } else return null;
      };

      const deleteMessageListener = (messageID) => {
        setOpen(false);
        setMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          delete newMessages[messageID];
          props.deleteLiveOrder();
          return newMessages;
        });
      };
      if (socket !== null) {
        socket.on(`New_order`, messageListener);
        socket.on("deleteMessage", deleteMessageListener);
        socket.emit("getMessages");
      }

      return () => {
        if (socket !== null) {
          socket.off(`New_order`, messageListener);
          socket.off("deleteMessage", deleteMessageListener);
        }
      };
    },
    // eslint-disable-next-line
    [socket]
  );

  React.useEffect(
    () => {
      if (
        props.percentagecouponsuccess ||
        props.Flatcouponsuccess ||
        props.Deliverycouponsuccess ||
        props.BXGYCouponssuccess
      ) {
        setTimeout(() => {
          props.history.push({
            pathname: `/home/Coupons`,
          });
        }, 2000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.percentagecouponsuccess,
      props.Flatcouponsuccess,
      props.Deliverycouponsuccess,
      props.BXGYCouponssuccess,
    ]
  );

  const handleClose = () => {
    let {
      orderstatus,
      deliveryDateFrom,
      deliveryDateTo,
      dateFrom,
      dateTo,
      orderNumber,
      customerMobile,
      first,
      offset,
    } = state;
    setOpen(false);
    props.getSearchOrderResult({
      orderstatus,
      deliveryDateFrom,
      deliveryDateTo,
      dateFrom,
      dateTo,
      orderNumber,
      customerMobile,
      first,
      offset,
    });
    props.deleteLiveOrder();
  };

  const close = () => {
    props.closeSnackBars();
    if (props.editStoreSuccess && props.editStoreSuccess === true) {
      props.history.push("/home/Stores");
    }
    if (props.deletenewproductsuccess && props.deletenewproductsuccess) {
      props.getNewProducts();
    }
  };

  const getDetails = (id) => {
    props.getOrderDetails(id);
    localStorage.setItem("orderID", id);
    props.history.push({
      pathname: "/home/Orderdetails",
      state: {
        orderid: id,
      },
    });
  };

  React.useEffect(
    () => {
      props.getDashboardData();
      props.getDepartmentList();
      props.getBusinessType();
    },
    // eslint-disable-next-line
    []
  );

  React.useEffect(
    () => {
      if (props.EditingCouponssuccess === true) props.getAllCoupons();
    },
    // eslint-disable-next-line
    [props.EditingCouponssuccess]
  );

  return (
    <NavBar selectedList={selectedList} id={props.match.params.id} {...props}>
      {props.editRetailerSuccess ||
      props.editRetailerFailed ||
      props.passwordResetSuccess ||
      props.editStoreSuccess ||
      props.editStoreFailed ||
      props.passwordResetFailed ||
      props.deletenewproductsuccess ||
      props.deletenewproductfailed ||
      props.percentagecouponsuccess ||
      props.percentagecouponFailed ||
      props.Flatcouponsuccess ||
      props.FlatcouponFailed ||
      props.Deliverycouponsuccess ||
      props.DeliverycouponFailed ||
      props.AssigningCouponssuccess ||
      props.AssigningCouponsFailed ||
      props.EditingCouponssuccess ||
      props.EditingCouponsFailed ||
      props.productAdded ||
      props.productEdited ||
      props.productEditFailed ||
      props.productAddedFailed ? (
        <Snackbar
          style={{ width: "fit-content" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={
            props.editRetailerSuccess ||
            props.editRetailerFailed ||
            props.passwordResetSuccess ||
            props.passwordResetFailed ||
            props.editStoreSuccess ||
            props.editStoreFailed ||
            props.deleFlatcouponFailedtenewproductsuccess ||
            props.deletenewproductfailed ||
            props.percentagecouponsuccess ||
            props.percentagecouponFailed ||
            props.Flatcouponsuccess ||
            props.FlatcouponFailed ||
            props.Deliverycouponsuccess ||
            props.DeliverycouponFailed ||
            props.AssigningCouponssuccess ||
            props.AssigningCouponsFailed ||
            props.EditingCouponssuccess ||
            props.EditingCouponsFailed ||
            props.productAdded ||
            props.productEdited ||
            props.productEditFailed ||
            props.productAddedFailed
          }
          autoHideDuration={2000}
          onClose={() => close()}
        >
          <Alert
            onClose={() => close()}
            severity={
              props.editRetailerSuccess ||
              props.passwordResetSuccess ||
              props.editStoreSuccess ||
              props.deletenewproductsuccess ||
              props.percentagecouponsuccess ||
              props.Flatcouponsuccess ||
              props.Deliverycouponsuccess ||
              props.AssigningCouponssuccess ||
              props.EditingCouponssuccess ||
              props.productAdded ||
              props.productEdited
                ? "success"
                : "error"
            }
          >
            <AlertTitle>
              {props.productAddedFailed
                ? `${props.ProductError}`
                : props.productAdded
                ? "Product added successfully"
                : props.productEdited
                ? "Product edited successfully"
                : props.productEditFailed
                ? "Product edit failed"
                : props.EditingCouponssuccess
                ? "Coupon status changed successfully"
                : props.EditingCouponsFailed
                ? `${props.Editingerror}`
                : props.AssigningCouponssuccess
                ? "Coupon assigned to user successfully"
                : props.AssigningCouponsFailed
                ? `${props.Assignerror}`
                : props.percentagecouponsuccess
                ? "Coupon added successfully"
                : props.Flatcouponsuccess
                ? "Coupon added successfully"
                : props.Deliverycouponsuccess
                ? "Coupon added successfully"
                : props.percentagecouponFailed
                ? "Coupon added failed"
                : props.FlatcouponFailed
                ? "Coupon added failed"
                : props.DeliverycouponFailed
                ? "Coupon added failed"
                : props.editStoreSuccess
                ? "Saved Successfully"
                : props.editStoreFailed
                ? "Edit Unsuccessfull"
                : props.editRetailerSuccess
                ? "Saved Successfully"
                : props.editRetailerFailed
                ? "Edit Unsuccessfull"
                : props.passwordResetSuccess
                ? "Successfully Changed Password"
                : props.passwordResetFailed
                ? "Failed to Reset Password"
                : props.deletenewproductsuccess
                ? "Deleted Successfully"
                : props.deletenewproductfailed
                ? "Delete failed"
                : null}
            </AlertTitle>
          </Alert>
        </Snackbar>
      ) : null}
      {socket &&
      props.LiveOrderData &&
      props.LiveOrderData !== null &&
      props.LiveOrderData.length > 0 ? (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
          <DialogTitle
            sx={{
              display: "flex",
              width: "100%",
              textAlign: "center",
              background: "rgba(133, 190, 73, 0.4)",
              color: "rgba(133, 190, 73, 1)",
              fontWeight: 800,
            }}
          >
            <span
              style={{
                display: "flex",
                width: "100%",
                textAlign: "center",
                justifyContent: "right",
                alignItems: "center",
                color: "rgba(133, 190, 73, 1)",
                fontWeight: 800,
                padding: 0,
              }}
            >
              {"New Order"}
            </span>
            <span
              style={{
                display: "flex",
                width: "100%",
                textAlign: "right",
                justifyContent: "right",
                alignItems: "right",
                float: "right",
                color: "rgba(133, 190, 73, 1)",
                fontWeight: 800,
                padding: 0,
              }}
            >
              <span
                onClick={handleClose}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "15px",
                  background: "rgba(133, 190, 73, 1)",
                  color: "#fff",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CloseIcon />
              </span>
            </span>
          </DialogTitle>
          <DialogContent>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {props.LiveOrderData.map((row, index) => (
                    <TableRow
                      hover
                      style={{ cursor: "pointer" }}
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      onClick={() => {
                        setOpen(false);
                        getDetails(row._id);
                      }}
                    >
                      <TableCell align="center">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {row.customerName && row.customerName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {row.orderNumber && row.orderNumber}
                      </TableCell>
                      <TableCell align="left">
                        {row.deliveryDate &&
                          new Date(row.deliveryDate).toString().slice(0, 15)}
                      </TableCell>
                      <TableCell align="left">
                        {row.deliveryTime && row.deliveryTime}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={
                            row.lastStatus === "Order-Placed"
                              ? "info"
                              : row.lastStatus === "Order-Accepted"
                              ? "primary"
                              : row.lastStatus === "Order-Rejected"
                              ? "error"
                              : row.lastStatus === "Order-Ready"
                              ? "warning"
                              : row.lastStatus === "Shopping-In-Progress"
                              ? "secondary"
                              : "success"
                          }
                        >
                          {row.lastStatus && row.lastStatus}
                        </Label>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      ) : null}
      <Route exact path="/home">
        <Dashboard {...props} />
      </Route>
      <Route exact path="/home/Stores">
        <StoreSearch {...props} />
      </Route>
      <Route exact path="/home/Storedetails">
        <StoreStepper {...props} />
      </Route>
      <Route exact path="/home/AddMasterProducts">
        <Add {...props} />
      </Route>
      <Route exact path="/home/Coupons">
        <Coupons {...props} />
      </Route>
      <Route exact path="/home/Coupons/Percentagediscount">
        <PercentageCoupon {...props} />
      </Route>
      <Route exact path="/home/Coupons/Flatdiscount">
        <FlatDiscount {...props} />
      </Route>
      <Route exact path="/home/Coupons/BuyXGetYfree">
        <BuyXgetYCoupon {...props} />
      </Route>
      <Route exact path="/home/Coupons/Freedelivery">
        <FreeDEliveryCoupon {...props} />
      </Route>
      <Route exact path="/home/EditMasterProducts">
        <UpdateProduct {...props} />
      </Route>
      <Route exact path="/home/EditMasterProducts/details">
        <ProductDetailsCard {...props} />
      </Route>
      <Route exact path="/home/Orders">
        <OrderSearch socket={socket} {...props} />
      </Route>
      <Route exact path="/home/Orderdetails">
        <OrderDetails {...props} />
      </Route>
      <Route
        exact
        path="/home/Orderdetails/GoogleMap"
        element={<GoogleMapOrder {...props} />}
      />
      <Route exact path="/home/ActiveOrders">
        <ActiveOrders {...props} />
      </Route>
      <Route exact path="/home/Users">
        <UsersList {...props} />
      </Route>
      <Route exact path="/home/Userdetails">
        <UserDetails {...props} />
      </Route>
      <Route exact path="/home/Complaints">
        <Complaints {...props} />
      </Route>
      <Route exact path="/home/Complaints/details">
        <Details {...props} />
      </Route>
      <Route exact path="/home/Dashboard">
        <Dashboard {...props} />
      </Route>
      <Route exact path="/home/NewProducts">
        <Productstable {...props} />
      </Route>
      <Route exact path="/home/Productdetails">
        <ProductDetails {...props} />
      </Route>
      <Route exact path="/home/MasterHierarchy">
        <Heirarchy {...props} />
      </Route>
      <Route exact path="/home/ExcelUpload">
        <ExcelUpload {...props} />
      </Route>

      <Route exact path={`/home/upload`}>
        <StoreUpload {...props} />
      </Route>
      <Route exact path="/home/Payment">
        <Payment {...props} />
      </Route>
      <Route exact path="/home/Payment/Completed">
        <Completed {...props} />
      </Route>
      <Route exact path="/home/Payment/NotProcessed/ViewTransaction">
        <PendingView {...props} />
      </Route>
      <Route exact path="/home/Payment/Completed/ViewTransaction">
        <View {...props} />
      </Route>
      <Route exact path="/home/Reports">
        <Reports {...props} />
      </Route>
      <Route exact path="/home/Settings">
        <Settings {...props} />
      </Route>
    </NavBar>
  );
};

export default SignIn;
