import {
  Grid,
  Box,
  Dialog,
    DialogTitle,
    InputAdornment,
    //makeStyles,
    Paper,
    Typography,
    useMediaQuery,    
    useTheme,
  } from "@mui/material";
import React, { useState } from 'react'
import CustomCard from '../CommonComponents/card';
import CustomButton from '../CommonComponents/button';
import "./PaymentTwo.css"
import View from './View';
import PendingView from './PendingView';
import { contentFontsize_14, contentFontSize_16, contentFontSize_20 } from "../Typography";
function PaymentTwo() {


  const orderList = [
    {
      orderNumber: "#1015",
      dates: "Fri : Jul 21 2023, 3:43 PM",
      price: "150",
      transferSettlement: [
        {
          status: "Completed",
          created: "Thu Sep 12 2024",
          createdTime: "13:31:34"
        }
      ],
      totalPayable: 200,
      transferSettlementStatus: "Completed"
    },
    {
      orderNumber: "#1017",
      dates: "Fri : Jul 21 2023, 3:43 PM",
      price: "200",
      transferSettlement: [
        {
          status: "Completed",
          created: "Thu Sep 12 2024",
          createdTime: "14:15:00"
        }
      ],
      totalPayable: 300,
      transferSettlementStatus: "Completed"
    },
    {
      orderNumber: "#1010",
      dates: "Fri : Aug 25 2023, 10:00 AM",
      price: "350",
      transferSettlement: [
        {
          status: "Transferred",
          created: "Fri Aug 25 2023",
          createdTime: "10:15:22"
        }
      ],
      totalPayable: 350,
      transferSettlementStatus: "Transferred"
    }
  ];
  const [state, setState] = useState("Settlements In-Progress");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [one,setOne] = useState(true)
  const [two, setTwo] = useState(false);
  const [three,setThree] = useState(false)
  const [view, setView] = useState(false);
  function handleView(){
    setView(true)
     console.log("hi")
  }
  
  

  return (
    <div style={{ paddingTop: "20px", boxSizing: "border-box" }}>
    {orderList?.map((order, index) => (
      <Grid sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
        <CustomCard
          padding={"30px"}
          width="100%"
          height="100%"
          borderLeft="8px solid #17A64F"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box>
            <Grid
              container
              spacing={2}
              sx={{ paddingLeft: "0px", paddingTop: "0px" }}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems:"flex-start",
                    justifyContent:"flex-start"
                  }}
                >
                  <Typography
                    className="Card-text"
                    fontSize={contentFontSize_20}
                    color={"#2D93FB"}
                    style={{ cursor: "pointer",textDecoration:"underline" }}
                    sx={{fontFamily:"Roboto",fontWeight:"600"}}
                    // onClick={() => handleOrder(order.id)}
                  >
                    {order?.orderNumber}
                  </Typography>
                  <Typography
                    fontSize={contentFontsize_14}
                    // className="Card-subtext"
                  >
                    {order?.transferSettlement[0]?.created}{" "}
                    {order?.transferSettlement[0]?.createdTime}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "15px",
                  }}
                >
                  <Typography
                    className="Card-price"
                    fontSize={contentFontSize_16}
                    color={"black"}
                    sx={{fontFamily:"Roboto",fontWeight:"600"}}
                  >
                    ₹ {order?.totalPayable ? order.totalPayable.toFixed(2) : '0.00'}
                  </Typography>
                </div>
              </Grid>

              {/* Adjusted status Grid item */}
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "15px",
                    whiteSpace: "nowrap", // Ensure the text doesn't wrap
                    overflow: "hidden", // Prevent overflow
                    textOverflow: "ellipsis", // Add ellipsis if the text overflows
                  }}
                >
                  <div className="dotTwo"></div>
                  <Typography
                    className="Card-price"
                    fontSize={contentFontSize_16}
                    color={"black"}
                    sx={{fontFamily:"Roboto",fontWeight:"600"}}
                  >
                   {order?.transferSettlementStatus}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <div
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    width="100%"
                    height="32px"
                    // onClick={() => handleViewClick(order)}
                  >
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        marginBottom: "0px",
                      }}
                    >
                      View Transaction
                    </Typography>
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </Box>
        </CustomCard>
      </Grid>
    ))}
  </div>
  )
}

export default PaymentTwo