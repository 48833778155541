import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  InputAdornment,
  //makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  DialogContent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./index.css";
import CustomTextField from "../CommonComponents/input";
import SearchIcon from "../utils/Icon/searchcopy.svg";
import CustomCard from "../CommonComponents/card";
import Arrow from "../utils/Icon/Arrow.svg";
import { SpaRounded } from "@material-ui/icons";
import CustomButton from "../CommonComponents/button";
import CustomBadge from "../CommonComponents/badge";
import PaymentTwo from "./PaymentTwo";
import PaymentOne from "./PaymentOne";
import {
  contentFontsize_14,
  contentFontsize_14_xs_10,
  contentFontSize_16,
  contentFontSize_20,
  HeaderNavigationFontSize_20,
  contentFontSize_18,
  contentFontSize_22,
} from "../Typography";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaymentIconImage from "../utils/Icon/PaymentIconImage.png";
import copyPayment from "../utils/Icon/copyPayment.png";
import closeIconPayment from "../utils/Icon/closeIconPayment.png";

function Completed(props) {
  // const orderList = [
  //   {
  //     orderNumber: "#1015",
  //     dates: "Fri : Jul 21 2023, 3:43 PM",
  //     price: "150",
  //     transferSettlement: [
  //       {
  //         status: "Completed",
  //         created: "Thu Sep 12 2024",
  //         createdTime: "13:31:34",
  //       },
  //     ],
  //     totalPayable: 200,
  //     transferSettlementStatus: "Completed",
  //   },
  //   {
  //     orderNumber: "#1017",
  //     dates: "Fri : Jul 21 2023, 3:43 PM",
  //     price: "200",
  //     transferSettlement: [
  //       {
  //         status: "Completed",
  //         created: "Thu Sep 12 2024",
  //         createdTime: "14:15:00",
  //       },
  //     ],
  //     totalPayable: 300,
  //     transferSettlementStatus: "Completed",
  //   },
  //   {
  //     orderNumber: "#1010",
  //     dates: "Fri : Aug 25 2023, 10:00 AM",
  //     price: "350",
  //     transferSettlement: [
  //       {
  //         status: "Transferred",
  //         created: "Fri Aug 25 2023",
  //         createdTime: "10:15:22",
  //       },
  //     ],
  //     totalPayable: 350,
  //     transferSettlementStatus: "Transferred",
  //   },
  // ];
  const [state, setState] = useState("Settlements In-Progress");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [orderSearch, setOrderSearch] = useState(false);
  const [searchOrder, setSearchOrder] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [first, setFirst] = useState(10);

  useEffect(() => {
    props.getAllCompletedSettlements({ offset: offset, first: first });
  }, []);

  const handleLoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset); 
    props.getAllCompletedSettlements({ offset: newOffset, first: first });

  };

  useEffect(() => {
    if (!props.CompletedPaymentData) {
      setTimeout(() => {
        setLoading(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    console.log("prep", props);
    if (props?.CompletedPaymentLoading) {
      console.log("true aayo", props);
    }
  }, []);

  useEffect(() => {
    const pendingOrders =
      props?.CompletedPaymentData?.data?.listAllCompletedSettlements?.items ||
      [];
    setOrderList(pendingOrders);
  }, [props?.CompletedPaymentData]);

  useEffect(() => {
    setSearchOrder(true);
    const pendingOrders =
      props?.completedSearchPaymentData?.searchCompletedSettlementsWithFilters
        ?.items || [];
    setOrderList(pendingOrders);
  }, [props.completedSearchPaymentData]);

  useEffect(() => {
    setOrderSearch(true);
    if (search === "") {
      props.getAllCompletedSettlements({ offset: offset, first: first });
    } else if (search.length === 5) {
      const searchAsFloat = parseFloat(search);
      props.getCompletedPaymentSearch({ orderNumber: searchAsFloat });
    }
  }, [search]);

  function handleClickTwo() {}

  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  function handleClickOne() {
    props.history.push({
      pathname: `/home/Payment`,
    });
  }

  const handleViewClick = (order) => {
    history.push("/home/Payment/Completed/ViewTransaction", {
      orderList: order,
    });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          transform: "translateY(100px)",
          width: "100%",
          borderRadius: "30px",
          height: "auto",
          padding: "15px",
          boxShadow:
            "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        }}
        position="sticky"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div onClick={handleClickOne} style={{ cursor: "pointer" }}>
            <Typography
              sx={{
                color: "#8B8989",
                fontFamily: "Roboto",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              Non Processed Transactions
            </Typography>
          </div>
          <div onClick={handleClickTwo} style={{ cursor: "pointer" }}>
            <Typography
              sx={{
                color: "#85BE49",
                fontFamily: "Roboto",
                fontWeight: "600",
                fontSize: "16px",
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
            >
              Completed
            </Typography>
          </div>
        </div>
        <div className="add-txt-orderHistory">
          <TextField
            placeholder="Search"
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            value={search}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D1D5DB",
                },
                borderRadius: "20px",
                "&.Mui-focused fieldset": {
                  borderColor: "#D1D5DB",
                },
              },
              "& .MuiInputBase-input": {
                backgroundColor: "transparent",
                borderRadius: "20px",
              },
            }}
            InputProps={{
              sx: {
                fontSize: matches && "13px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={SearchIcon}
                    alt="Search"
                    style={{ marginRight: "8px" }}
                  />
                </InputAdornment>
              ),
            }}
            size={matches ? "small" : "medium"}
          />
        </div>
        <div style={{ paddingTop: "20px", boxSizing: "border-box" }}>
          {orderList?.map((order, index) => (
            <Grid sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
              <CustomCard
                padding={"30px"}
                width="100%"
                height="100%"
                borderLeft="8px solid #17A64F"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box>
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                  >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          className="Card-text"
                          fontSize={contentFontSize_20}
                          color={"#2D93FB"}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                          // onClick={() => handleOrder(order.id)}
                        >
                          #{order?.orderNumber}
                        </Typography>
                        <Typography
                          fontSize={contentFontsize_14}
                          // className="Card-subtext"
                        >
                          {order?.transferSettlement[0]?.created}{" "}
                          {order?.transferSettlement[0]?.createdTime}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <Typography
                          className="Card-price"
                          fontSize={contentFontSize_16}
                          color={"black"}
                          sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                        >
                          ₹{" "}
                          {order?.totalPayable
                            ? order.totalPayable.toFixed(2)
                            : "0.00"}
                        </Typography>
                      </div>
                    </Grid>

                    {/* Adjusted status Grid item */}
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "15px",
                          whiteSpace: "nowrap", // Ensure the text doesn't wrap
                          overflow: "hidden", // Prevent overflow
                          textOverflow: "ellipsis", // Add ellipsis if the text overflows
                        }}
                      >
                        <div className="dotTwo"></div>
                        <Typography
                          className="Card-price"
                          fontSize={contentFontSize_16}
                          color={"black"}
                          sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                        >
                          {order?.transferSettlementStatus}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <div
                        style={{
                          marginTop: "12px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CustomButton
                          width="100%"
                          height="32px"
                          onClick={() => handleViewClick(order)}
                        >
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              marginBottom: "0px",
                            }}
                          >
                            View Transaction
                          </Typography>
                        </CustomButton>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CustomCard>
            </Grid>
          ))}
        </div>
        {!loading && searchOrder && orderList.length === 0 && (
          <div
            style={{
              marginTop: "8%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={HeaderNavigationFontSize_20}
              className="Add-product-btn"
            >
              No Results Found.
            </Typography>
          </div>
        )}
        {/* {three && <Completed />} */}
        {/* {loading && (
          <div
            style={{
              marginTop: "8%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={HeaderNavigationFontSize_20}
              className="Add-product-btn"
            >
              Loading...
            </Typography>
          </div>
        )} */}
        {props?.CompletedPaymentData?.data?.listAllCompletedSettlements
          ?.hasNext && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
            }}
          >
            <CustomButton onClick={handleLoadMore}>Load More</CustomButton>
          </div>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              padding: "20px 0px 0px 0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "98%",
                top: "-5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={closeIconPayment} alt="closeIcon" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                style={{ fontFamily: "Roboto", fontWeight: "700" }}
                fontSize={contentFontSize_22}
              >
                ₹
                {/* {selectedTransaction?.totalPayable
                  ? selectedTransaction.totalPayable.toFixed(2)
                  : "0.00"} */}
              </Typography>
              <Typography
                style={{ fontFamily: "Roboto", fontWeight: "500" }}
                fontSize={contentFontsize_14_xs_10}
              >
                autopay by razor pay/03245676543
              </Typography>
            </div>
            <div
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                padding: "20px",
                marginTop: "35px",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                fontSize={contentFontSize_18}
              >
                Transaction Details
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Transaction ID
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    S6578654 <span style={{ margin: "0 1px" }}></span>
                    <img src={copyPayment} alt="no image" />
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Status
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    {/* {selectedTransaction?.transferSettlement?.length > 0
                      ? selectedTransaction.transferSettlement[0].status
                      : "No status available"} */}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Transaction Type
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Credit
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Date & Time
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    {/* {selectedTransaction?.transferSettlement?.[0]?.created ||
                      "N/A"}{" "}
                    |{" "}
                    {selectedTransaction?.transferSettlement?.[0]
                      ?.createdTime || "N/A"} */}
                  </Typography>
                </div>
              </div>

              <div style={{ position: "absolute", top: "-7%", left: "42%" }}>
                <img src={PaymentIconImage} alt="paymentIcon" />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Completed;
