import React, { useState, useEffect } from "react";
import {
  AdapterDayjs,
  DateTimePicker,
  //   DemoContainer,
  Grid,
  InputAdornment,
  LocalizationProvider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import {
  HeaderFontSize_24,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_22,
} from "../Typography";
import CustomBadge from "../CommonComponents/badge/index";
//import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Dialog } from "../MUIComponents/index";
import { DialogContent } from "../MUIComponents/index";
import closeIconPayment from "../utils/images/closeIconPayment.png";
import PaymentIconImage from "../utils/images/PaymentIconImage.png";
import copyPayment from "../utils/images/copyPayment.png";
import dayjs from "dayjs";
import calander_new from "../utils/images/calander_new.png";
import searchcopy from "../utils/Icon/searchcopy.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomButton from "../CommonComponents/button";
import Download from "../utils/Icon/download.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as XLSX from "xlsx";
import "./report.css";

function createData(
  no,
  name,
  calories,
  fat,
  carbs,
  delivery,
  platform,
  nearshopz,
  date,
  gstdelivery,
  gstmarkup,
  gstplatform,
  coupon,
  couponburn,
  markup,
  productprice,
  sellerfee,
  deliveryperson
) {
  return {
    no,
    name,
    calories,
    fat,
    carbs,
    delivery,
    platform,
    nearshopz,
    date,
    gstdelivery,
    gstmarkup,
    gstplatform,
    coupon,
    couponburn,
    markup,
    productprice,
    sellerfee,
    deliveryperson,
  };
}

const headerStyle = {
  // width:"60px",
  backgroundColor: "rgba(84, 93, 95, 0.15)",
  color: "black",
  textAlign: "center",
  border: "1px solid white",
};

const cellStyle = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderRight: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
  borderLeft: "1px solid rgba(84, 93, 95, 0.15)",
};

const cellStyle2 = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderRight: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
};

const cellStyle4 = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderLeft: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
  color: "black",
  textDecoration: "underline",
};

const cellStyle1 = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderRight: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
  borderLeft: "1px solid rgba(84, 93, 95, 0.15)",
  color: "black",
  textDecoration: "underline",
  cursor: "pointer",
};
const containerStyle = {
  width: "100%",
  overflowX: "auto",
  maxWidth: "1200px",
};

function Reports(props) {
  const [newState, setNewState] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState(false);

  const validationSchema = Yup.object().shape({
    dateFrom: Yup.date().nullable(),
    dateTo: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      dateFrom: null,
      dateTo: null,
      first: 10,
      offset: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSearch(true);
      const dateFrom = values.dateFrom ? new Date(values.dateFrom) : null;
      const dateTo = values.dateTo ? new Date(values.dateTo) : null;
      await props.getCompletedPaymentSearch({
        dateFrom: dateFrom,
        dateTo: dateTo,
        first,
        offset,
      });
    },
    onReset: () => {
      setSearch(false);
      setOffset(0);
      formik.setValues({
        dateFrom: null,
        dateTo: null,
        first: 10,
        offset: 0,
      });
      props.getAllCompletedSettlements({ offset: 0, first: first });
    },
  });

  const handleSearchLoadMore = async () => {
    const dateFrom = formik.values.dateFrom
      ? new Date(formik.values.dateFrom)
      : null;
    const dateTo = formik.values.dateTo ? new Date(formik.values.dateTo) : null;
    const newFirst = formik.values.first + formik.values.first;
    const newOffset = formik.values.first;
    await props.getCompletedPaymentSearch({
      dateFrom: dateFrom,
      dateTo: dateTo,
      first: formik.values.first + formik.values.first,
      offset: formik.values.first,
    });
  };

  useEffect(() => {
    const pendingOrders =
      props?.completedSearchPaymentData?.searchCompletedSettlementsWithFilters
        ?.items || [];
    setRows(pendingOrders);
  }, [props.completedSearchPaymentData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();

  const [first, setFirst] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    props.getAllCompletedSettlements({ offset: offset, first: first });
  }, []);

  useEffect(() => {
    const pendingOrders =
      props?.CompletedPaymentData?.data?.listAllCompletedSettlements?.items ||
      [];
    setRows(pendingOrders);
  }, [props?.CompletedPaymentData]);

  const handleLoadMore = () => {
    const newFirst = first + 10;
    props.getAllCompletedSettlements({ offset: first, first: newFirst });

    setOffset(first);
    setFirst(newFirst);
  };

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const handleDownloadExcel = () => {
    const pendingOrders =
      props?.CompletedPaymentData?.data?.listAllCompletedSettlements?.items ||
      [];

    if (pendingOrders?.length === 0) {
      console.error("No data available for download");
      return;
    }

    // Define the headings
    const headings = [
      [
        "Order ID",
        "Order Date",
        "Product Price",
        "Markup on Product Price",
        "Packing Charges",
        "Markup on Packing Charges",
        "Delivery Charge",
        "Markup on Delivery Charge",
        "Delivery Amount to NearShopz Partner",
        "Delivery Amount to External Partner",
        "Platform Fee",
        "GST for Product Price",
        "GST for Packing Charges",
        "GST for Product + Packing Charges",
        "GST for Delivery",
        "GST for Platform Fee",
        "Coupons",
        "Coupon Burn",
      ],
    ];

    // Map the data to the format for Excel
    const dataForExcel = pendingOrders?.map((order) => [
      `#${order.orderNumber}` || "", // Order number
      order.deliveryDate, // Delivery date
      `₹${(order.shoppingTotalPrice ?? 0).toFixed(2)}`, // Shopping total price
      order.shoppingTotalPrice != null &&
      order.shoppingTotalPriceWithoutMarkup != null
        ? `₹${(
            order.shoppingTotalPrice - order.shoppingTotalPriceWithoutMarkup
          ).toFixed(2)}`
        : `₹${(order.shoppingTotalPrice ?? 0).toFixed(2)}`, // Shopping price minus markup
      `₹${(order.shoppingPackingCharge ?? 0).toFixed(2)}`, // Packing charge
      order.shoppingPackingCharge != null &&
      order.shoppingPackingChargeWithoutMarkup != null
        ? `₹${(
            order.shoppingPackingCharge -
            order.shoppingPackingChargeWithoutMarkup
          ).toFixed(2)}`
        : `₹${(order.shoppingPackingCharge ?? 0).toFixed(2)}`, // Packing charge minus markup
      `₹${(order.deliveryCharge ?? 0).toFixed(2)}`, // Delivery charge
      order.deliveryCharge != null && order.deliveryChargeWithoutMarkup != null
        ? `₹${(
            order.deliveryCharge - order.deliveryChargeWithoutMarkup
          ).toFixed(2)}`
        : `₹${(order.deliveryCharge ?? 0).toFixed(2)}`, // Delivery charge minus markup
      `₹${(
        order.editedAmountForNearshopzDeliveryPerson ??
        order.amountForNearshopzDeliveryPerson ??
        0
      ).toFixed(2)}`, // Nearshopz delivery amount
      `₹${(
        order.editedAmountForExternalDeliveryPerson ??
        order.amountForExternalDeliveryPerson ??
        0
      ).toFixed(2)}`, // External delivery amount
      `₹${(order.platformFee ?? 0).toFixed(2)}`, // Platform fee
      `₹${(order.totalPriceGST ?? 0).toFixed(2)}`, // Total price GST
      `₹${(order.packingChargeGstAmount ?? 0).toFixed(2)}`, // Packing charge GST
      `₹${(
        (order.totalPriceGST ?? 0) + (order.packingChargeGstAmount ?? 0)
      ).toFixed(2)}`, // Total + Packing GST
      `₹${(order.deliveryChargeGstAmount ?? 0).toFixed(2)}`, // Delivery charge GST
      `₹${(order.platformFeeGstAmount ?? 0).toFixed(2)}`, // Platform fee GST
      `₹${(order.shoppingDiscountPrice ?? order.discountPrice ?? 0).toFixed(
        2
      )}`, // Discount price
      `₹0.00`,
    ]);

    // Check if dataForExcel is properly defined
    if (!Array.isArray(dataForExcel)) {
      console.error("Error processing data for Excel");
      return;
    }

    // Combine headings and data
    const worksheetData = [...headings, ...dataForExcel];

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stores");

    // Generate the Excel file
    XLSX.writeFile(workbook, "order_reports.xlsx");
  };

  function MuiIcon() {
    return (
      <img
        src={calander_new}
        alt="Calendar icon"
        width="22px"
        height="21.99px"
      />
    );
  }
  return (
    <div
      style={{
        backgroundColor: "white",
        transform: "translateY(100px)",
        width: "100%",
        borderRadius: "30px",
        height: "auto",
        padding: "15px",
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      }}
      position="sticky"
    >
      <div style={{ textAlign: "left", textTransform: "uppercase" }}>
        <Typography
          style={{ fontFamily: "Roboto", fontWeight: "600" }}
          fontSize={HeaderNavigationFontSize_20}
        >
          Reports
        </Typography>
      </div>
      <div>
        <div style={{ width: "100%", margin: "40px 0px 30px 0px" }}>
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div style={{ flex: 1 }}>
                <TextField
                  type="date"
                  // label="Delivery From"
                  name="dateFrom"
                  value={formik.values.dateFrom}
                  onChange={formik.handleChange}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </div>

              <div style={{ flex: 1 }}>
                <TextField
                  type="date"
                  // label="Delivery To"
                  name="dateTo"
                  value={formik.values.dateTo}
                  onChange={formik.handleChange}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </div>

              <div>
                <CustomButton
                  color="rgb(255, 255, 255)"
                  backgroundColor="rgb(137, 199, 74, 1)"
                  height="50px"
                  borderRadius="20px"
                  width="280px"
                  type="submit" // This will trigger formik's onSubmit
                >
                  Apply
                </CustomButton>
              </div>

              <div>
                <CustomButton
                  color="black"
                  background="transparent"
                  height="50px"
                  borderRadius="20px"
                  borderColor="rgb(137, 199, 74, 1)"
                  width="280px"
                  onClick={() => {
                    formik.resetForm(); // Reset the form when Clear is clicked
                  }}
                >
                  Clear
                </CustomButton>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{
            ...containerStyle,
            overflowX: "auto",
          }}
          className="custom-scrollbar"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={headerStyle}>Order ID</TableCell>
                <TableCell sx={headerStyle}>Order Date</TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Product Price
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Markup on Product Price
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Packing Charges
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Markup on Packing Charges
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Delivery Charge
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Markup on Delivery Charge
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Delivery Amount to NearShopz Partner
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Delivery Amount to External Partner
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Platform Fee{" "}
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Product Price{" "}
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Packing Charges{" "}
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Product + Packing Charges
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Delivery
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Platform Fee
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Coupons
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Coupon Burn
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.no}>
                  <TableCell component="th" scope="row" sx={cellStyle2}>
                    #{row.orderNumber}
                  </TableCell>
                  <TableCell sx={cellStyle1}>{row.deliveryDate}</TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.shoppingTotalPrice ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={cellStyle}
                    onClick={handleClickOpen}
                  >
                    {row.shoppingTotalPrice != null &&
                    row.shoppingTotalPriceWithoutMarkup != null
                      ? `₹${(
                          row.shoppingTotalPrice -
                          row.shoppingTotalPriceWithoutMarkup
                        ).toFixed(2)}`
                      : `₹${(row.shoppingTotalPrice ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.shoppingPackingCharge ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {row.shoppingPackingCharge != null &&
                    row.shoppingPackingChargeWithoutMarkup != null
                      ? `₹${(
                          row.shoppingPackingCharge -
                          row.shoppingPackingChargeWithoutMarkup
                        ).toFixed(2)}`
                      : `₹${(row.shoppingPackingCharge ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.deliveryCharge ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {row.deliveryCharge != null &&
                    row.deliveryChargeWithoutMarkup != null
                      ? `₹${(
                          row.deliveryCharge - row.deliveryChargeWithoutMarkup
                        ).toFixed(2)}`
                      : `₹${(row.deliveryCharge ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(
                      row.editedAmountForNearshopzDeliveryPerson ??
                      row.amountForNearshopzDeliveryPerson ??
                      0
                    ).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(
                      row.editedAmountForExternalDeliveryPerson ??
                      row.amountForExternalDeliveryPerson ??
                      0
                    ).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.platformFee ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.totalPriceGST ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.packingChargeGstAmount ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(
                      (row.totalPriceGST ?? 0) +
                      (row.packingChargeGstAmount ?? 0)
                    ).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.deliveryChargeGstAmount ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.platformFeeGstAmount ?? 0).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(
                      row.shoppingDiscountPrice ??
                      row.discountPrice ??
                      0
                    ).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    0.00
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {search &&
          props?.completedSearchPaymentData
            ?.searchCompletedSettlementsWithFilters?.items.length !=
            props?.completedSearchPaymentData
              ?.searchCompletedSettlementsWithFilters?.count && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <CustomButton onClick={handleSearchLoadMore}>
                Load More
              </CustomButton>
            </div>
          )}
        {!search &&
          props?.CompletedPaymentData?.data?.listAllCompletedSettlements
            ?.hasNext && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <CustomButton onClick={handleLoadMore}>Load More</CustomButton>
            </div>
          )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "10px",
        }}
      >
        <Typography
          style={{
            textAlign: "right",
            marginTop: "2%",
            color: "black",
            fontWeight: "500",
          }}
          fontSize={HeaderNavigationFontSize_20}
          onClick={handleDownloadExcel}
        >
          Download Report
        </Typography>
        <img src={Download} style={{ marginBottom: "5px" }} />
      </div>
    </div>
  );
}

export default Reports;
