import { React, useEffect, useState } from "react";
import CustomDialog from "../CommonComponents/dialog";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  contentFontSize_18,
  contentFontSize_20,
  HeaderFontSize_25,
  HeaderFontSize_24,
  contentFontSize_22,
  contentFontsize_28,
  contentFontsize_24,
} from "../Typography/index";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  InputAdornment,
  Select,
  MenuItem,
  DialogContent,
  //makeStyles,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./PendingView";
import { useLocation } from "react-router-dom";
import Amount from "../utils/Icon/Amount.svg";
import deliver from "../utils/Icon/scooter.svg";
import payment from "../utils/Icon/paymentmethod.svg";
import edit from "../utils/Icon/Edit1.svg";
import save from "../utils/Icon/Save.svg";
import CustomCard from "../CommonComponents/card";
import CustomButton from "../CommonComponents/button";

function TransferPopup(props) {
  const [addAccount, setAddAccount] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    legal_business_name: "",
    business_type: "",
    razorPayAccountId: "",
    customer_facing_business_name: "",
    category: "",
    subcategory: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    linkedAccountUser: 3,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddClick = () => {
    props.addAccount({
      email: formData.email,
      phone: formData.phone,
      legal_business_name: formData.legal_business_name,
      business_type: formData.business_type,
      razorPayAccountId: formData.razorPayAccountId,
      customer_facing_business_name: formData.customer_facing_business_name,
      category: formData.category,
      subcategory: formData.subcategory,
      street1: formData.street1,
      street2: formData.street2,
      city: formData.city,
      state: formData.state,
      postal_code: formData.postal_code,
      country: formData.country,
      profile: formData.profile,
      linkedAccountUser: parseFloat(formData.linkedAccountUser),
    });
    setAddAccount(false);
  };

  useEffect(() => {
    props?.getExternalDeliveryPersonLinkedAccounts({ linkedAccountUser: 3 });
  }, []);

  useEffect(() => {
    const acc =
      props?.getLinkedAccountsData?.data
        ?.getExternalDeliveryPersonLinkedAccounts || [];
    console.log("account", acc);
    setAccounts(acc);
  }, [props?.getLinkedAccountsData]);

  const handleClose = () => {
    props.setTransfer(false);
    setAddAccount(false);
  };

  const handleClose1 = () => {
    setAddAccount(false);
  };

  useEffect(() => {
    // console.log("page open");
    // console.log("transfer", props.transfer);
  });

  const handleAddAccountClick = () => {
    setAddAccount(true);
  };

  const [externalSelected, setExternalSelected] = useState("");
 

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setExternalSelected(selectedValue);

    const selectedAccount = accounts.find(
      (account) => account.business_type === selectedValue
    );

    console.log("acc",selectedAccount)

    if (selectedAccount) {
      props.setSelectedAccountId(selectedAccount.razorPayAccountId);
    }
  };

  const handleSaveClick = (event) => {
    event.preventDefault();
    props.setSelectedTransfer(externalSelected);
    props.setFirstClick(true);
    props.setTransfer(false);
  };

  return (
    <>
      {props?.transfer && (
        <Dialog
          width="100%"
          height="600px"
          borderRadius="20px"
          open={props?.transfer}
          onClose={handleClose}
          showCloseButton={true}
          right="20px"
          top="20px"
          bottom="20px"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px",
                // Set your width here
                padding: "10px 10px 30px 10px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogTitle>
            <Typography
              fontSize={contentFontsize_28}
              sx={{
                fontFamily: "Roboto",
                fontWeight: "700",
                display: "flex",
                // justifyContent: "center",
                color: "black",
                marginTop: "20px",
              }}
            >
              Transfer
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <div
                style={{
                  height: "27px",
                  width: "27px",
                  border: "2px solid #8B8989",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <CloseIcon style={{ color: "#8B8989", alignItems: "right" }} />
              </div>
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ padding: "0px 24px" }}>
            <form>
              <div>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                  }}
                  fontSize={contentFontSize_18}
                >
                  autopay by razor pay/03245676543 autopay by razor
                  pay/03245676543 autopay by razor pay/03245676543
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              ></div>

              <div style={{ marginTop: "20px" }}>
                <Typography sx={{ color: "black" }}>
                  Choose Linked Account
                </Typography>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Select
                  className="custom-select_1"
                  variant="outlined"
                  style={{ width: "100%" }}
                  SelectProps={{ style: { width: "100%", height: 35 } }}
                  InputLabelProps={{ shrink: true }}
                  name="status"
                  value={externalSelected}
                  onChange={handleSelectChange}
                >
                  {accounts && accounts.length > 0 ? (
                    accounts.map((account, index) => (
                      <MenuItem key={index} value={account?.business_type}>
                        {account?.business_type}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No accounts available</MenuItem>
                  )}
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: "20px",
                }}
              >
                <CustomButton width="150px" onClick={handleAddAccountClick}>
                  <Typography
                    style={{
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                    }}
                    fontSize={ButtonFontSize_18}
                  >
                    Add Account
                  </Typography>
                </CustomButton>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <div>
                  <CustomButton
                    color="black"
                    background="transparent"
                    height="40px"
                    borderRadius="20px"
                    borderColor="rgb(137, 199, 74, 1)"
                    width="280px"
                    // onClick={handleCancel}
                  >
                    Cancel
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    color="rgb(255, 255, 255)"
                    backgroundColor="rgb(137, 199, 74, 1)"
                    height="40px"
                    borderRadius="20px"
                    width="280px"
                    onClick={handleSaveClick}
                  >
                    Save
                  </CustomButton>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}
      {addAccount && (
        <>
          <Dialog
            fullWidth
            maxWidth="md"
            borderRadius="20px"
            open={addAccount}
            onClose={handleClose1}
            showCloseButton={true}
            PaperProps={{
              style: {
                borderRadius: 20,
                padding: "20px",
              },
            }}
          >
            <DialogTitle>
              <Typography
                fontSize={24}
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "black",
                  marginTop: "20px",
                }}
              >
                Add Account
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose1}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "black",
                }}
              >
                <div
                  style={{
                    height: "27px",
                    width: "27px",
                    border: "2px solid #8B8989",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon style={{ color: "#8B8989" }} />
                </div>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Email */}
                <Grid item xs={4}>
                  <label>Email</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Phone Number */}
                <Grid item xs={4}>
                  <label>Phone Number</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Legal Business Name */}
                <Grid item xs={4}>
                  <label>Legal Business Name</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="legal_business_name"
                    value={formData.legal_business_name}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Customer Facing Business Name */}
                <Grid item xs={4}>
                  <label>Customer Facing Business Name</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="customer_facing_business_name"
                    value={formData.customer_facing_business_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <label>Business Type</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="business_type"
                    value={formData.business_type}
                    onChange={handleChange}
                  />
                </Grid>

                {/* RazorPay Account ID */}
                <Grid item xs={4}>
                  <label>Razor Pay AccountId</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="razorPayAccountId"
                    value={formData.razorPayAccountId}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Profile Section */}
                <Grid item xs={12}>
                  <Typography
                    fontSize={18}
                    sx={{ marginTop: "20px", fontWeight: "600" }}
                  >
                    Profile
                  </Typography>
                </Grid>

                {/* Category */}
                <Grid item xs={4}>
                  <label>Category</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Subcategory */}
                <Grid item xs={4}>
                  <label>Subcategory</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="subcategory"
                    value={formData.subcategory}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Address Section */}
                <Grid item xs={12}>
                  <Typography
                    fontSize={18}
                    sx={{ marginTop: "20px", fontWeight: "600" }}
                  >
                    Address
                  </Typography>
                </Grid>

                {/* Street 1 */}
                <Grid item xs={4}>
                  <label>Street 1</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="street1"
                    value={formData.street1}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Street 2 */}
                <Grid item xs={4}>
                  <label>Street 2</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="street2"
                    value={formData.street2}
                    onChange={handleChange}
                  />
                </Grid>

                {/* City */}
                <Grid item xs={4}>
                  <label>City</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </Grid>

                {/* State */}
                <Grid item xs={4}>
                  <label>State</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Postal Code */}
                <Grid item xs={4}>
                  <label>Postal Code</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Country */}
                <Grid item xs={4}>
                  <label>Country</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Linked Account User */}
                <Grid item xs={4}>
                  <label>Linked Account User</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputProps={{ style: { height: 35 } }}
                    variant="outlined"
                    name="linkedAccountUser"
                    value={formData.linkedAccountUser}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
              </Grid>

              {/* Buttons */}
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <CustomButton
                  color="black"
                  background="transparent"
                  height="40px"
                  borderRadius="20px"
                  borderColor="rgb(137, 199, 74, 1)"
                  width="280px"
                  onClick={handleClose1}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  color="white"
                  backgroundColor="rgb(137, 199, 74, 1)"
                  height="40px"
                  borderRadius="20px"
                  width="280px"
                  onClick={handleAddClick}
                >
                  Add
                </CustomButton>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}

export default TransferPopup;
