import React, { useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@material-ui/core';
import ListItemButton from '@mui/material/ListItemButton';

// third-party

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import Transitions from '../../ui-component/extended/Transitions';
// import UpgradePlanCard from './UpgradePlanCard';

// assets
import { IconLogout,IconSettings } from '@tabler/icons';
import { LogOut } from 'react-feather';
import Logout from '../Logout';
// import User1 from 'assets/images/users/user-round.svg';

// style const
const useStyles = makeStyles(() => ({
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: 'white',
        borderRadius: '10px',
        // [theme.breakpoints.down('sm')]: {
        //     minWidth: '100%'
        // }
    },
    headerAvatar: {
        cursor: 'pointer',
        // ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: '#B9F6CA',
        backgroundColor: '#B9F6CA',
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor:'#00E676',
            backgroundColor: '#00E676',
            color: '#B9F6CA',
            // '& svg': {
            //     stroke: '#B9F6CA'
            // }
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px',
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
        width:'50%',
        backgroundColor:'red'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: '#B9F6CA',
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: '#9E9E9E'
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: '#FFC107',
        color: '#fff'
    }
}));

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = (props) => {
    const classes = useStyles();
    // const customization = useSelector((state) => state.customization);
    const [selectedIndex] = React.useState(1);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [log,setLog] = useState(false)
    const handleLogout = async () => {
        //  console.error('Logout');
        // localStorage.clear('retailerid','token')
        // //setLog(true)
		// props.history.push({pathname:`/`})
        setLog(true)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        // if (anchorRef.current && anchorRef.current.contains(event.target)) {
        //     return;
        // }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    return (
        <>
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                icon={
                    <Avatar
                    src="/broken-image.jpg"
                        style={{ backgroundColor: '#FFC107' }}
                        alt="Remy Sharp"
                        // className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    >A
                    </Avatar>
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={'#00C853'} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper elevation={16} style={{backgroundColor:'white',borderRadius:'14px',marginTop:'12px',marginRight:'76px',width:'42%',position: 'relative',
    left: '48%'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                            <MainCard border={false} elevation={0} content={false} boxShadow 
                                shadow={'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px'}>
                                    <CardContent className={classes.cardContent}>
                                            <List component="nav" className={classes.navContainer}>
                                                <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: `12px`,display:'flex',justifyContent:'center',alignItems:'center' }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                  
                                                        {'Logout'}
                                                    {/* <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} /> */}
                                                </ListItemButton>
                                            </List>
                                          </CardContent>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            {log && (
                <Logout  />
            )}
        </>
    );
};

export default ProfileSection;