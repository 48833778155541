import React, { useEffect, useState } from "react";
import { ButtonFontSize_18, HeaderNavigationFontSize_20 } from "../Typography";
import { InputAdornment, TextField, Typography } from "@mui/material";
import CustomButton from "../CommonComponents/button";
import { useFormik } from "formik";
import EditIcon from "../utils/Icon/Edit1.svg";
import diableEdit from "../utils/Icon/disableEdit.svg";
import { Grid } from "@mui/material";

function Settings(props) {
  const [markupEnable, setMarkupEnable] = useState(false);
  const [gstEnable, setGstEnable] = useState(false);
  const [platformFee, setPlatformFee] = useState(false);
  const [settingsValue, setSettingsValue] = useState([]);

  const handleMarkupClick = () => {
    setMarkupEnable(true);
  };

  const handleGstClick = () => {
    setGstEnable(true);
  };

  const handlePlatformClick = () => {
    setPlatformFee(true);
  };

  useEffect(() => {
    props.getSettings();
  }, []);

  useEffect(() => {
    const values = props?.SettingsData?.data?.getSettings;
    if (values) {
      setSettingsValue(values);
      console.log("Settings Data:", values); // You should log `values` instead of `settingsValue` here
    }
  }, [props.SettingsData]);

  const formik = useFormik({
    enableReinitialize: true, // This ensures formik updates its initial values when settingsValue changes
    initialValues: {
      defaultRestaurantProductMarkupPercent: (
        settingsValue?.defaultRestaurantProductMarkupPercent ?? 0
      ).toFixed(2),
      defaultPackingMarkupPercent: (
        settingsValue?.defaultPackingMarkupPercent ?? 0
      ).toFixed(2),
      defaultDeliveryChargeMarkupPercent: (
        settingsValue?.defaultDeliveryChargeMarkupPercent ?? 0
      ).toFixed(2),
      defaultPlatformFeePercent: (
        settingsValue?.defaultPlatformFeePercent ?? 0
      ).toFixed(2),
      restaurantProductPriceGSTPercent: (
        settingsValue?.restaurantProductPriceGSTPercent ?? 0
      ).toFixed(2),
      restaurantPackingChargeGSTPercent: (
        settingsValue?.restaurantPackingChargeGSTPercent ?? 0
      ).toFixed(2),
      supermarketPackingChargeGSTPercent: (
        settingsValue?.supermarketPackingChargeGSTPercent ?? 0
      ).toFixed(2),
      deliveryChargeGSTPercent: (
        settingsValue?.deliveryChargeGSTPercent ?? 0
      ).toFixed(2),
      platformFeeGSTPercent: (
        settingsValue?.platformFeeGSTPercent ?? 0
      ).toFixed(2),
    },

    onSubmit: (values) => {
      setMarkupEnable(false);
      setGstEnable(false);
      setPlatformFee(false);
      console.log("Submitted values:", values);
      props.updateAdminSettings(values);
    },
  });

  console.log("Formik Values:", formik.values);

  return (
    <div
      style={{
        backgroundColor: "white",
        transform: "translateY(100px)",
        width: "100%",
        borderRadius: "30px",
        height: "auto",
        padding: "15px",
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      }}
      position="sticky"
    >
      <div style={{ textAlign: "left", marginTop: "2%", marginLeft: "38px" }}>
        <Typography
          style={{ fontFamily: "Roboto", fontWeight: "600", color: "black" }}
          fontSize={HeaderNavigationFontSize_20}
        >
          Settings
        </Typography>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            paddingRight: "35px",
          }}
        >
          <img
            src={markupEnable ? diableEdit : EditIcon}
            onClick={handleMarkupClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Grid
          container
          spacing={3}
          style={{
            padding: "10px 0 30px 0",
            borderBottom: "1px dashed #e0e0e0",
            marginTop: "2%",
          }}
        >
          {/* First Row */}
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ paddingLeft: "42px" }}>
              {/* Restaurant Product Markup */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Restaurant <br />
                    Product Markup
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    name="defaultRestaurantProductMarkupPercent"
                    value={formik.values.defaultRestaurantProductMarkupPercent}
                    style={{ width: "80%" }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: markupEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!markupEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Restaurant Packing Markup */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                   Packing Charges <br/>
                   Markup
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    name="defaultPackingMarkupPercent"
                    value={formik.values.defaultPackingMarkupPercent}
                    style={{ width: "80%" }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: markupEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!markupEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Restaurant Delivery Charge Markup */}
              <Grid
                item
                xs={12}
                md={4}
                container
                spacing={2}
                sx={{ paddingLeft: "25px" }}
              >
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                
                    Delivery Charge   <br /> Markup
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    name="defaultDeliveryChargeMarkupPercent"
                    value={formik.values.defaultDeliveryChargeMarkupPercent}
                    style={{ width: "80%" }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: markupEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!markupEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Second Row (Similar to Above) */}
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ paddingLeft: "42px" }}>
              {/* Restaurant Platform Fee */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6} sx={{paddingTop:"20px"}}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    
                    Platform<br /> Fee
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingTop:"20px"
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    name="defaultPlatformFeePercent"
                    value={formik.values.defaultPlatformFeePercent}
                    style={{ width: "80%" }}
                    variant="outlined"
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: markupEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!markupEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
            paddingRight: "35px",
          }}
        >
          <img
            src={gstEnable ? diableEdit : EditIcon}
            onClick={handleGstClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Grid
          container
          spacing={3}
          style={{
            padding: "10px 0 30px 0",
            borderBottom: "1px dashed #e0e0e0",
            marginTop: "2%",
          }}
        >
          {/* First Row */}
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ paddingLeft: "42px" }}>
              {/* Restaurant Product Markup */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Restaurant <br />
                    Product Price GST
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ width: "80%" }}
                    variant="outlined"
                    name="restaurantProductPriceGSTPercent"
                    value={formik.values.restaurantProductPriceGSTPercent}
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: gstEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!gstEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Normal state
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Focused state (removes blue border)
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Restaurant Packing Markup */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Restaurant <br />
                    Packing Charge GST
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ width: "80%" }}
                    variant="outlined"
                    name="restaurantPackingChargeGSTPercent"
                    value={formik.values.restaurantPackingChargeGSTPercent}
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: gstEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!gstEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Normal state
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Focused state (removes blue border)
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Restaurant Delivery Charge Markup */}
              <Grid
                item
                xs={12}
                md={4}
                container
                spacing={2}
                sx={{ paddingLeft: "25px" }}
              >
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Supermarket
                    <br />
                    Packing Charge GST
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ width: "80%" }}
                    variant="outlined"
                    name="supermarketPackingChargeGSTPercent"
                    value={formik.values.supermarketPackingChargeGSTPercent}
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: gstEnable ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!gstEnable}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Normal state
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Focused state (removes blue border)
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Second Row (Similar to Above) */}
        </Grid>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
            paddingRight: "35px",
          }}
        >
          <img
            src={platformFee ? diableEdit : EditIcon}
            onClick={handlePlatformClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Grid
          container
          spacing={3}
          style={{
            padding: "10px 0 30px 0",
            // borderBottom: "1px dashed #e0e0e0",
            marginTop: "2%",
          }}
        >
          {/* First Row */}
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ paddingLeft: "42px" }}>
              {/* Restaurant Product Markup */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Delivery
                    <br />
                    Charge GST
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ width: "80%" }}
                    variant="outlined"
                    name="deliveryChargeGSTPercent"
                    value={formik.values.deliveryChargeGSTPercent}
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: platformFee ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!platformFee}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Normal state
                        },

                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Focused state (removes blue border)
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Restaurant Packing Markup */}
              <Grid item xs={12} md={4} container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Platform
                    <br />
                    Fee GST
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ width: "80%" }}
                    variant="outlined"
                    name="platformFeeGSTPercent"
                    value={formik.values.platformFeeGSTPercent}
                    onChange={formik.handleChange}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: "18px" }}
                        >
                          | %
                        </InputAdornment>
                      ),
                      style: {
                        color: platformFee ? "#D40000" : "#979797",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={!platformFee}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Normal state
                        },

                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(84, 93, 95, 0.75)", // Focused state (removes blue border)
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Restaurant Delivery Charge Markup */}
              <Grid
                item
                xs={12}
                md={4}
                container
                spacing={2}
                sx={{ paddingLeft: "25px" }}
              >
                <Grid item xs={6}></Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                ></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "40px 0 30px 0",
            // borderBottom: "1px solid #e0e0e0",
          }}
        >
          <CustomButton
            variant="contained"
            style={{ backgroundColor: "#4CAF50", color: "white" }}
            width="30%"
            borderRadius="15px"
            type="submit"
          >
            <Typography
              fontSize={ButtonFontSize_18}
              style={{ textTransform: "none", fontWeight: "500" }}
            >
              Save Changes
            </Typography>
          </CustomButton>
        </div>
      </form>
    </div>
  );
}

export default Settings;
